import gql from 'graphql-tag'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const ArticleBlocksImageOrVideoFragment = gql`
  fragment ArticleBlocksImageOrVideoFragment on articleBlocks_imageOrVideo_BlockType {
    typeHandle
    alt
    asset {
      ...CraftAssetFragment
    }
    autoplay
    backgroundGray
  }
  ${CraftAssetFragment}
`
export const EducationBlocksImageOrVideoFragment = gql`
  fragment EducationBlocksImageOrVideoFragment on educationBlocks_imageOrVideo_BlockType {
    typeHandle
    alt
    asset {
      ...CraftAssetFragment
    }
    autoplay
    backgroundGray
  }
  ${CraftAssetFragment}
`

export const MarketingBlocksImageOrVideoFragment = gql`
  fragment MarketingBlocksImageOrVideoFragment on marketingBlocks_imageOrVideo_BlockType {
    typeHandle
    alt
    asset {
      ...CraftAssetFragment
    }
    autoplay
    backgroundGray
  }
  ${CraftAssetFragment}
`

export const ShopBlocksImageOrVideoFragment = gql`
  fragment ShopBlocksImageOrVideoFragment on shopBlocks_imageOrVideo_BlockType {
    typeHandle
    alt
    asset {
      ...CraftAssetFragment
    }
    autoplay
    backgroundGray
  }
  ${CraftAssetFragment}
`

export const StoriesPageBlocksImageOrVideoFragment = gql`
  fragment StoriesPageBlocksImageOrVideoFragment on storiesPageBlocks_imageOrVideo_BlockType {
    typeHandle
    alt
    asset {
      ...CraftAssetFragment
    }
    autoplay
    backgroundGray
  }
  ${CraftAssetFragment}
`
