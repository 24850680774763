<template>
  <div class="sm:container my-l">
    <h2 class="fluid-3xl leading-tightest my-8 hidden sm:block">{{ heading }}</h2>
    <div class="grid grid-cols-4 gap-10">
      <div class="col-span-4 sm:col-span-2">
        <LargeStoryPreview
          v-if="hasFocusStory && focusStoryTitle"
          v-track:view="gtmData(focusStory[0], 0)"
          v-track:click="gtmData(focusStory[0], 0)"
          :story="focusStory[0]"
          :story-title="focusStoryTitle"
        />
        <div
          v-else-if="!hasFocusStory && focusStoryTitle"
          class="text-white flex flex-col box relative sm:sticky top-32"
        >
          <h3 class="fluid-3xl leading-tightest" v-html="focusStoryTitle"></h3>
          <CtaArrow v-if="focusStoryCta" v-bind="focusStoryCta" class="cta-arrow mt-4" />
        </div>
      </div>
      <div class="px-3 sm:px-0 col-span-4 sm:col-span-2 masonry">
        <StoryPreview
          v-for="(story, index) in stories"
          :key="`${index} ${story.uri} ${$i18n.locale}`"
          v-track:view="gtmData(story, index + 1)"
          v-track:click="gtmData(story, index + 1)"
          class="masonry-item my-4 sm:my-0"
          :always-column="false"
          :story="story"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import StoryPreview, { StoryPreviewFragment } from '@/components/stories/StoryPreview';
import { LinkFragment } from '@/components/common/LinkField';
import LargeStoryPreview from '@/components/stories/LargeStoryPreview';
import CtaArrow from '@/components/common/CtaArrow';

export const StoriesPageBlocksLargeStoryFeatureWithBarsFragment = gql`
  fragment StoriesPageBlocksLargeStoryFeatureWithBarsFragment on storiesPageBlocks_largeStoryFeatureWithBars_BlockType {
    typeHandle
    heading
    focusStoryTitle
    focusStory {
      uri
      ...StoryPreviewFragment
    }
    focusStoryCta {
      ...LinkFragment
    }
    stories {
      uri
      ...StoryPreviewFragment
    }
  }
  ${StoryPreviewFragment}
  ${LinkFragment}
`;

export const MarketingBlocksLargeStoryFeatureWithBarsFragment = gql`
  fragment MarketingBlocksLargeStoryFeatureWithBarsFragment on marketingBlocks_largeStoryFeatureWithBars_BlockType {
    typeHandle
    heading
    focusStoryTitle
    focusStory {
      uri
      ...StoryPreviewFragment
    }
    focusStoryCta {
      ...LinkFragment
    }
    stories {
      uri
      ...StoryPreviewFragment
    }
  }
  ${StoryPreviewFragment}
  ${LinkFragment}
`;

export default {
  components: {
    StoryPreview,
    LargeStoryPreview,
    CtaArrow,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    focusStory: {
      type: Array,
      default: () => [],
    },
    focusStoryTitle: {
      type: String,
      default: '',
    },
    stories: {
      type: Array,
      default: () => [],
    },
    focusStoryCta: {
      type: [Object, String],
      default: () => null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    hasFocusStory() {
      return !!this.focusStory[0];
    },
  },
  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.uri,
        promotion_name: [this.$route?.name, payload.title].join(' / '),
        creative_name: 'largeStoryFeatureWithBars',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 640px) {
  .masonry {
    column-count: 2;
    column-gap: 2rem;
  }

  .masonry-item {
    break-inside: avoid;
  }
}
</style>
