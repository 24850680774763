<template>
  <div v-track:view="gtmData()" class="my-l container">
    <div class="md:flex-nowrap flex flex-wrap">
      <div class="md:w-1/2 md:px-20 md:order-0 order-1 w-full">
        <div v-if="heading" class="fluid-3xl font-semibold leading-tightest pr-8" v-html="heading"></div>
        <div v-if="teaserText" class="fluid-base leading-relaxed font-light text-copy mt-8" v-html="teaserText"></div>
        <AppStoreLinks class="mt-4" v-bind="{ blockPosition }" />
      </div>
      <div class="md:w-1/2 order-0 md:order-1 relative w-full">
        <FixedAspect
          :ratio="aspectRatio"
          :class="{ 'ratio-absolute  md:w-full md:h-full md:z-10 md:!absolute translate-y-[-30%];': imageOverlap }"
        >
          <CldnImg
            v-if="image"
            :src="image.url"
            :alt="alt ? alt : image.title"
            sizes="(min-width: 1440px) 648px,(min-width: 1024px) 50vw, 100vw"
            crop="fill"
          />
        </FixedAspect>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import AppStoreLinks from '@/components/common/AppStoreLinks';

const GlobalAppCtaQuery = gql`
  query GlobalAppCtaQuery($site: [String!]) {
    globalSet(site: $site, handle: "appCta") {
      ... on appCta_GlobalSet {
        heading
        teaserText
        image {
          url
          width
          height
          title
        }
        imageOverlap
        alt
      }
    }
  }
`;

export const ShopBlocksAppCtaFragment = gql`
  fragment ShopBlocksAppCtaFragment on shopBlocks_appCta_BlockType {
    typeHandle
  }
`;

export const ExercisesBlocksAppCtaFragment = gql`
  fragment ExercisesBlocksAppCtaFragment on exercisesBlocks_appCta_BlockType {
    typeHandle
    position
  }
`;

export const MarketingBlocksAppCtaFragment = gql`
  fragment MarketingBlocksAppCtaFragment on marketingBlocks_appCta_BlockType {
    typeHandle
  }
`;

export const ProductBlocksAppCtaFragment = gql`
  fragment ProductBlocksAppCtaFragment on productBlocks_appCta_BlockType {
    typeHandle
  }
`;

export const ArticleBlocksAppCtaFragment = gql`
  fragment ArticleBlocksAppCtaFragment on articleBlocks_appCta_BlockType {
    typeHandle
  }
`;

export default {
  components: { AppStoreLinks, FixedAspect, CldnImg },
  props: {
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      heading: null,
      teaserText: null,
      alt: null,
      imageOverlap: false,
      image: null,
    };
  },

  async fetch() {
    try {
      const {
        globalSet: { heading, teaserText, image, alt, imageOverlap },
      } = await this.$craftRequest(GlobalAppCtaQuery, {
        site: this.$i18n.locale,
      });

      this.heading = heading;
      this.teaserText = teaserText;
      this.imageOverlap = imageOverlap;
      this.image = image[0];
      this.alt = alt;
    } catch (e) {
      console.error(e);
    }
  },

  computed: {
    aspectRatio() {
      return this.image?.height / this.image?.width || 1;
    },
  },

  methods: {
    gtmData() {
      return {
        promotion_id: this.heading?.toLowerCase().replace(/ /g, '-'),
        promotion_name: [this.$route?.name, this.heading].join(' / '),
        creative_name: 'appCta',
        creative_slot: [this.$route?.name, this.blockPosition + 1].join(' / '),
      };
    },
  },
};
</script>
