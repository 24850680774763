import gql from 'graphql-tag'
import { LinkFragment } from '~/components/common/LinkField'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const ArticleBlocksFullWidthTeaserBoxFragment = gql`
  fragment ArticleBlocksFullWidthTeaserBoxFragment on articleBlocks_fullWidthTeaserBox_BlockType {
    id
    typeHandle
    tag
    heading
    teaser
    animationEnabled
    boxPosition
    boxOutside
    image {
      ...CraftAssetFragment
    }
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`

export const ExercisesBlocksFullWidthTeaserBoxFragment = gql`
  fragment ExercisesBlocksFullWidthTeaserBoxFragment on exercisesBlocks_fullWidthTeaserBox_BlockType {
    id
    typeHandle
    tag
    heading
    teaser
    animationEnabled
    boxPosition
    boxOutside
    image {
      ...CraftAssetFragment
    }
    cta {
      ...LinkFragment
    }
    position
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const TopicBlocksFullWidthTeaserBoxFragment = gql`
  fragment TopicBlocksFullWidthTeaserBoxFragment on topicBlocks_fullWidthTeaserBox_BlockType {
    id
    typeHandle
    tag
    heading
    teaser
    animationEnabled
    boxPosition
    boxOutside
    image {
      ...CraftAssetFragment
    }
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const MarketingBlocksFullWidthTeaserBoxFragment = gql`
  fragment MarketingBlocksFullWidthTeaserBoxFragment on marketingBlocks_fullWidthTeaserBox_BlockType {
    typeHandle
    heading
    tag
    teaser
    boxPosition
    boxOutside
    animationEnabled
    image {
      ...CraftAssetFragment
    }
    alt
    cta {
      ...LinkFragment
    }
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`
export const ShopBlocksFullWidthTeaserBoxFragment = gql`
  fragment ShopBlocksFullWidthTeaserBoxFragment on shopBlocks_fullWidthTeaserBox_BlockType {
    typeHandle
    heading
    tag
    teaser
    boxPosition
    boxOutside
    animationEnabled
    image {
      ...CraftAssetFragment
    }
    alt
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
