import gql from 'graphql-tag'
import { LinkFragment } from '~/components/common/LinkField'

export const EducationBlocksCenteredContentFragment = gql`
  fragment EducationBlocksCenteredContentFragment on educationBlocks_centeredContent_BlockType {
    typeHandle
    heading
    body
    backgroundBlack
    cta {
      ...LinkFragment
    }
    icons: educationBlocksCenteredContentIcons {
      ... on educationBlocksCenteredContentIcons_BlockType {
        id
        heading
        icon {
          url
        }
      }
    }
  }
  ${LinkFragment}
`

export const MarketingBlocksCenteredContentFragment = gql`
  fragment MarketingBlocksCenteredContentFragment on marketingBlocks_centeredContent_BlockType {
    typeHandle
    heading
    body
    backgroundBlack
    cta {
      ...LinkFragment
    }
    icons: marketingBlocksCenteredContentIcons {
      ... on marketingBlocksCenteredContentIcons_BlockType {
        id
        heading
        icon {
          url
        }
      }
    }
  }
  ${LinkFragment}
`

export const ShopBlocksCenteredContentFragment = gql`
  fragment ShopBlocksCenteredContentFragment on shopBlocks_centeredContent_BlockType {
    typeHandle
    heading
    body
    backgroundBlack
    cta {
      ...LinkFragment
    }
    icons: shopBlocksCenteredContentIcons {
      ... on shopBlocksCenteredContentIcons_BlockType {
        id
        heading
        icon {
          url
        }
      }
    }
  }
  ${LinkFragment}
`

export const AboutBlocksCenteredContentFragment = gql`
  fragment AboutBlocksCenteredContentFragment on aboutBlocks_centeredContent_BlockType {
    typeHandle
    heading
    body
    backgroundBlack
    cta {
      ...LinkFragment
    }
    icons: aboutBlocksCenteredContentIcons {
      ... on aboutBlocksCenteredContentIcons_BlockType {
        id
        heading
        icon {
          url
        }
      }
    }
  }
  ${LinkFragment}
`
export const ProductBlocksCenteredContentFragment = gql`
  fragment ProductBlocksCenteredContentFragment on productBlocks_centeredContent_BlockType {
    typeHandle
    heading
    body
    backgroundBlack
    cta {
      ...LinkFragment
    }
    icons: productBlocksCenteredContentIcons {
      ... on productBlocksCenteredContentIcons_BlockType {
        id
        heading
        icon {
          url
        }
      }
    }
  }
  ${LinkFragment}
`
