import gql from 'graphql-tag'

export const AboutBlocksGenericIframeFragment = gql`
  fragment AboutBlocksGenericIframeFragment on aboutBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const ArticleBlocksGenericIframeFragment = gql`
  fragment ArticleBlocksGenericIframeFragment on articleBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const EducationBlocksGenericIframeFragment = gql`
  fragment EducationBlocksGenericIframeFragment on educationBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const ExercisesBlocksGenericIframeFragment = gql`
  fragment ExercisesBlocksGenericIframeFragment on exercisesBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const TopicBlocksGenericIframeFragment = gql`
  fragment TopicBlocksGenericIframeFragment on topicBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const MarketingBlocksGenericIframeFragment = gql`
  fragment MarketingBlocksGenericIframeFragment on marketingBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const ShopBlocksGenericIframeFragment = gql`
  fragment ShopBlocksGenericIframeFragment on shopBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const StoriesPageBlocksGenericIframeFragment = gql`
  fragment StoriesPageBlocksGenericIframeFragment on storiesPageBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`

export const ProductBlocksGenericIframeFragment = gql`
  fragment ProductBlocksGenericIframeFragment on productBlocks_genericIframe_BlockType {
    typeHandle
    iframeCode
    isContained
  }
`
