<template>
  <CommonAccordionBlock v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import CommonAccordionBlock from '@/components/common/blocks/AccordionBlock'
export const ProductBlocksAccordionBlockFragment = gql`
  fragment ProductBlocksAccordionBlockFragment on productBlocks_accordionBlock_BlockType {
    typeHandle
    heading
    backgroundGreen
    accordions: productAccordions {
      ... on productAccordions_BlockType {
        id
        heading
        body
      }
    }
  }
`
export default {
  components: { CommonAccordionBlock },
  props: {
    heading: {
      type: String,
      default: ''
    },
    backgroundGreen: {
      type: Boolean,
      default: false
    },
    accordions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    indicator() {
      return this.active ? '-' : '+'
    }
  }
}
</script>
