import gql from 'graphql-tag';
import { transformMetaField } from '@/utils/shopify';

export const productMetaFieldTransformer = (productData) => {
  const metafieldKeys = [
    'accordion',
    'badges',
    'bundleProduct',
    'cardHeader',
    'exercises',
    'flags',
    'keyFeatures',
    'optionSwatches',
    'productCardUsp',
    'relatedProducts',
    'testimonial',
    'video',
    'comparison',
    'digitalAddOn',
    'digitalProductType',
    'subtitle',
  ];

  metafieldKeys.forEach((field) => transformMetaField(productData, field));

  // check if accordion or keyFeatures contain the string "Made in Germany"
  // if so, set isMadeInGermany to true
  productData.isMadeInGermany = false; // default value
  if (productData._accordion?.value?.includes('Made in Germany')) {
    productData.isMadeInGermany = true;
  }

  if (productData._keyFeatures?.value?.includes('Made in Germany')) {
    productData.isMadeInGermany = true;
  }

  return productData;
};

export const productDigitalProductTypeFragment = gql`
  fragment productDigitalProductTypeFragment on Product {
    _digitalProductType: metafield(namespace: "oneworld", key: "digitalProductType") {
      value
      type
    }
  }
`;
export const productDigitalAddOnFragment = gql`
  fragment productDigitalAddOnFragment on Product {
    _digitalAddOn: metafield(namespace: "oneworld", key: "digitalAddOn") {
      value
      type
    }
  }
`;
export const productBundleProductFragment = gql`
  fragment productBundleProductFragment on Product {
    _bundleProduct: metafield(namespace: "oneworld", key: "bundleProduct") {
      value
      type
    }
  }
`;
export const productAccordionFragment = gql`
  fragment productAccordionFragment on Product {
    _accordion: metafield(namespace: "oneworld", key: "accordion") {
      value
      type
    }
  }
`;
export const productExercisesFragment = gql`
  fragment productExercisesFragment on Product {
    _exercises: metafield(namespace: "oneworld", key: "exercises") {
      value
      type
    }
  }
`;
export const productTestimonialFragment = gql`
  fragment productTestimonialFragment on Product {
    _testimonial: metafield(namespace: "oneworld", key: "testimonial") {
      value
      type
    }
  }
`;
export const productVideoFragment = gql`
  fragment productVideoFragment on Product {
    _video: metafield(namespace: "oneworld", key: "video") {
      value
      type
    }
  }
`;
export const productOptionSwatchesFragment = gql`
  fragment productOptionSwatchesFragment on Product {
    _optionSwatches: metafield(namespace: "oneworld", key: "optionSwatches") {
      value
      type
    }
  }
`;
export const productBadgesFragment = gql`
  fragment productBadgesFragment on Product {
    _badges: metafield(namespace: "oneworld", key: "badges") {
      value
      type
    }
  }
`;
export const productRelatedProductsFragment = gql`
  fragment productRelatedProductsFragment on Product {
    _relatedProducts: metafield(namespace: "oneworld", key: "relatedProducts") {
      value
      type
    }
  }
`;
export const productKeyFeaturesFragment = gql`
  fragment productKeyFeaturesFragment on Product {
    _keyFeatures: metafield(namespace: "oneworld", key: "keyFeatures") {
      value
      type
    }
  }
`;
export const productFlagsFragment = gql`
  fragment productFlagsFragment on Product {
    _flags: metafield(namespace: "oneworld", key: "flags") {
      value
      type
    }
  }
`;
export const productCardHeaderFragment = gql`
  fragment productCardHeaderFragment on Product {
    _cardHeader: metafield(namespace: "oneworld", key: "cardHeader") {
      value
      type
    }
  }
`;
export const productProductCardUspFragment = gql`
  fragment productProductCardUspFragment on Product {
    _productCardUsp: metafield(namespace: "oneworld", key: "productCardUsp") {
      value
      type
    }
  }
`;
export const productComparisonFragment = gql`
  fragment productComparisonFragment on Product {
    _comparison: metafield(namespace: "oneworld", key: "comparison") {
      value
      type
    }
  }
`;

export const digitalProductTypeFragment = gql`
  fragment digitalProductTypeFragment on Product {
    _digitalProductType: metafield(namespace: "oneworld", key: "digitalProductType") {
      value
      type
    }
  }
`;

export const subtitleFragment = gql`
  fragment subtitleFragment on Product {
    _subtitle: metafield(namespace: "oneworld", key: "subtitle") {
      value
      type
    }
  }
`;
