import gql from 'graphql-tag'

export const MarketingBlocksConversionAreaOneFragment = gql`
  fragment MarketingBlocksConversionAreaOneFragment on marketingBlocks_conversionAreaOne_BlockType {
    typeHandle
    isAdjusted
    shopifyHandle
  }
`
export const EducationBlocksConversionAreaOneFragment = gql`
  fragment EducationBlocksConversionAreaOneFragment on educationBlocks_conversionAreaOne_BlockType {
    typeHandle
    isAdjusted
    shopifyHandle
  }
`
