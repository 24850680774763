<template>
  <div class="py-20 bg-putty px-4 md:px-0">
    <div class="container">
      <h2 class="w-full text-center">{{ heading }}</h2>
      <div class="checkmark-bullets checkmark-noborder md:checkmark-bullets-centered w-full md:max-w-screen-md mx-auto">
        <ul class="mt-8 grid md:grid-cols-2 gap-x-4">
          <li
            v-for="(row, index) in points"
            :key="index"
            class="w-full text-left"
            :class="{ 'mx-auto': isLastOdd(index) }"
          >
            {{ row.point }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureList',
  props: {
    heading: {
      type: String,
      default: null
    },
    points: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isLastOdd(index) {
      return this.points?.length % 2 !== 0 && index === this.points?.length - 1
    }
  }
}
</script>
