export const FEG_ADDRESS_FRAGMENT = `
  fragment FEG_ADDRESS_FRAGMENT on BlackrollCustomerAddress {
    firstName
    lastName
    company
    address
    address2
    city
    zipcode
    country
    phone
    defaultAddress
  }
`

export const FEG_ORDER_ADDRESS_FRAGMENT = `
  fragment FEG_ORDER_ADDRESS_FRAGMENT on BlackrollOrderAddress {
    firstName
    lastName
    company
    address
    address2
    city
    zipcode
    country
    phone
  }
`

export const FEG_ORDER_FRAGMENT = `
  ${FEG_ORDER_ADDRESS_FRAGMENT}
  fragment FEG_ORDER_FRAGMENT on BlackrollOrder {
    orderNumber
    date
    totalPrice
    trackingUrl
    paymentDetails
    paymentStatus
    deliveryStatus
    variantThumbnail
    subtotalPrice
    includedShipping
    includedDiscounts
    totalAmountRefunded

    orderItems {
      variantThumbnail
      sku
      productHandle
      productName
      productTitle
      variantTitle
      quantity
      itemPrice
      subtotalItemPrice
      totalItemPrice
      discountPercent
      totalDiscountAmount
    }

    deliveryAddress {
      ...FEG_ORDER_ADDRESS_FRAGMENT
    }

    billingAddress {
      ...FEG_ORDER_ADDRESS_FRAGMENT
    }
    orderDetails {
      type
      erpOrderNumber
      orderPdfUrl
    }
  }
`

const FEG_WORKOUT_FRAGMENT = `
  fragment FEG_WORKOUT_FRAGMENT on BlackrollCustomerWorkout
  {
     routineObjectId
     pimId
  }
`

const FEG_EXERCISE_FRAGMENT = `
  fragment FEG_EXERCISE_FRAGMENT on BlackrollCustomerExercise
  {
     exerciseKey
     pimId
  }
`

const FEG_ADDON_FRAGMENT = `
  fragment FEG_ADDON_FRAGMENT on BlackrollAddOn
  {
      sku
      handle
      redeemedAt
      redeemedFrom
      code
      state
  }
`

const FEG_LEARNWORLDS_ACCOUNT_FRAGMENT = `
  fragment FEG_LEARNWORLDS_ACCOUNT_FRAGMENT on LearnWorldsAccount
  {
    userId
    username
    academyLanguage
    courses {
      handle
      courseId
      price
      progress {
        status
        progressRate
        averageScoreRate
        timeOnCourse
        totalUnits
        completedUnits
      }
    }
  }
`

export const FEG_DATA_GQL = `
  ${FEG_ADDRESS_FRAGMENT}
  ${FEG_ORDER_FRAGMENT}
  ${FEG_EXERCISE_FRAGMENT}
  ${FEG_WORKOUT_FRAGMENT}
  ${FEG_ADDON_FRAGMENT}
  ${FEG_LEARNWORLDS_ACCOUNT_FRAGMENT}
  {
    blackrollCustomer {
      email
      displayName

      addresses {
        ...FEG_ADDRESS_FRAGMENT
      }

      orders {
        ...FEG_ORDER_FRAGMENT
      }

      exercises {
        ...FEG_EXERCISE_FRAGMENT
      }

      workouts {
        ...FEG_WORKOUT_FRAGMENT
      }

      addOns {
        ...FEG_ADDON_FRAGMENT
      }

      learnWorldsAccount {
        ...FEG_LEARNWORLDS_ACCOUNT_FRAGMENT
      }
    }
  }
`
export const FEG_DEMAND_DATA_GQL = `
  ${FEG_ADDRESS_FRAGMENT}
  ${FEG_ORDER_FRAGMENT}
  ${FEG_EXERCISE_FRAGMENT}
  ${FEG_WORKOUT_FRAGMENT}
  ${FEG_ADDON_FRAGMENT}
  {
    demandBlackrollCustomer {
      email
      displayName

      addresses {
        ...FEG_ADDRESS_FRAGMENT
      }

      orders {
        ...FEG_ORDER_FRAGMENT
      }

      exercises {
        ...FEG_EXERCISE_FRAGMENT
      }

      workouts {
        ...FEG_WORKOUT_FRAGMENT
      }

      addOns {
        ...FEG_ADDON_FRAGMENT
      }
    }
  }
`

export const FEG_UPDATE_ADDRESSES = `
  ${FEG_ADDRESS_FRAGMENT}
  mutation updateCustomerAddresses($addresses: [BlackrollCustomerAddressInput]!) {
    blackrollCustomerAddressUpdate(addresses: $addresses) {
      ...FEG_ADDRESS_FRAGMENT
    }
  }
`

export const FEG_CUSTOMER_UPDATE = `
  mutation customerUpdate($customer: CustomerEntityInput!) {
    customerUpdate(customer: $customer) {
      displayName
    }
  }
`

export const FEG_ADD_EXERCISE = `
  mutation AddExercise($exercise: String!, $pimId: Long) {
    blackrollExerciseAdd(exerciseKey: $exercise, pimId: $pimId) {
      exerciseKey
      pimId
    }
  }
`

export const FEG_DELETE_EXERCISE = `
  mutation DeleteExercise($exercise: String!, $pimId: Long) {
    blackrollExerciseDelete(exerciseKey: $exercise, pimId: $pimId) {
      exerciseKey
      pimId
    }
  }
`

export const FEG_ADD_WORKOUT = `
  mutation AddWorkout($workout: String!, $pimId: Long) {
    blackrollWorkoutAdd(routineObjectId: $workout, pimId: $pimId) {
      routineObjectId
      pimId
    }
  }
`

export const FEG_DELETE_WORKOUT = `
  mutation DeleteWorkout($workout: String!, $pimId: Long) {
    blackrollWorkoutDelete(routineObjectId: $workout, pimId: $pimId) {
      routineObjectId
      pimId
    }
  }
`

export const FEG_GET_MULTIPASS_GQL = `
  query GetMultipass($language: String!, $returnUrl: String!) {
    shopifyMultipass(
      shopifyMultipass: {
        language: $language,
        returnTo: $returnUrl
      }
    ) {
      token
    }
  }
`

export const FEG_ADD_ADDON = `
  mutation AddAddon($code: String!) {
    redeemCode(code: $code) {
      code
      state
    }
  }
`

export const FEG_GET_LEARNWORLDS_SSO = `
  query GetLearnworldsSSO($courseId: String, $redirectUrl: String) {
    learnWorldsLogin(courseId: $courseId, redirectUrl: $redirectUrl) {
      url
      success
      learnWorldsUserId
      errors
    }
  }
`
