<template>
  <div class="container flex flex-wrap my-20">
    <div v-if="heading || cta" class="flex flex-wrap my-8 w-full">
      <h4 v-if="heading" class="w-full md:w-1/2">{{ heading }}</h4>
      <div v-if="cta" class="w-full md:w-1/2 text-right hidden md:flex flex-col-reverse pr-8">
        <CtaArrow v-bind="cta" class="cta-arrow" data-test-id="cta" />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row sm:space-x-16 w-full space-y-16 sm:space-y-0">
      <TopicFeature
        v-for="(topic, index) in topics"
        :key="topic.id"
        v-track:view="gtmData(topic, index)"
        v-track:click="gtmData(topic, index)"
        :topic="topic"
        class="w-full sm:w-1/2"
        data-test-id="topic"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { LinkFragment } from '@/components/common/LinkField';
import TopicFeature from '@/components/knowledge/topic/TopicFeature';
import CtaArrow from '@/components/common/CtaArrow';

export const MarketingBlocksSmallTopicFeatureBoxFragment = gql`
  fragment MarketingBlocksSmallTopicFeatureBoxFragment on marketingBlocks_smallTopicFeatureBox_BlockType {
    id
    typeHandle
    heading
    cta {
      ...LinkFragment
    }
    topics {
      ... on topics_BlockType {
        heading
        image {
          url
          title
        }
        body
        cta {
          ...LinkFragment
        }
        entry {
          ... on knowledge_topic_Entry {
            title
            categories: knowledgeCategories {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
  ${LinkFragment}
`;

export const TopicBlocksSmallTopicFeatureBoxFragment = gql`
  fragment TopicBlocksSmallTopicFeatureBoxFragment on topicBlocks_smallTopicFeatureBox_BlockType {
    id
    typeHandle
    heading
    cta {
      ...LinkFragment
    }
    topics {
      ... on topics_BlockType {
        heading
        image {
          url
          title
        }
        body
        cta {
          ...LinkFragment
        }
        entry {
          ... on knowledge_topic_Entry {
            title
            categories: knowledgeCategories {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
  ${LinkFragment}
`;

export const EducationBlocksSmallTopicFeatureBoxFragment = gql`
  fragment EducationBlocksSmallTopicFeatureBoxFragment on educationBlocks_smallTopicFeatureBox_BlockType {
    id
    typeHandle
    heading
    cta {
      ...LinkFragment
    }
    topics: educationSTFBtopics {
      ... on educationSTFBtopics_BlockType {
        heading
        image {
          url
          title
        }
        body
        cta {
          ...LinkFragment
        }
        entry {
          ... on knowledge_topic_Entry {
            title
            categories: knowledgeCategories {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
  ${LinkFragment}
`;

export default {
  components: {
    TopicFeature,
    CtaArrow,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: () => {},
    },
    topics: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.cta?.element?.uri,
        promotion_name: [this.$route?.name, payload.heading].join(' / '),
        creative_name: 'smallTopicFeatureBox',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
