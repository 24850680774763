import gql from 'graphql-tag';
import { CraftAssetFragment } from '@/components/common/CraftAsset';

export { default } from './ImageGallery.vue';

export const ArticleBlocksGalleryFragment = gql`
  fragment ArticleBlocksGalleryFragment on articleBlocks_gallery_BlockType {
    typeHandle
    slides {
      ... on slides_BlockType {
        id
        image {
          ...CraftAssetFragment
        }
        alt
        caption
      }
    }
  }
  ${CraftAssetFragment}
`;

export const StoriesPageBlocksImageGalleryFragment = gql`
  fragment StoriesPageBlocksImageGalleryFragment on storiesPageBlocks_imageGallery_BlockType {
    typeHandle
    slides {
      ... on slides_BlockType {
        id
        image {
          ...CraftAssetFragment
        }
        alt
        caption
      }
    }
  }
  ${CraftAssetFragment}
`;

export const ProductBlocksImageGalleryFragment = gql`
  fragment ProductBlocksImageGalleryFragment on productBlocks_imageGallery_BlockType {
    typeHandle
    slides {
      ... on slides_BlockType {
        id
        image {
          ...CraftAssetFragment
        }
        alt
        caption
      }
    }
  }
  ${CraftAssetFragment}
`;
