<template>
  <div
    class="items-center px-10 py-16 lg:text-center"
    :class="[backgroundBlack ? 'bg-blackly text-white' : 'bg-putty', { 'container my-16': layout === 'contained' }]"
  >
    <div class="sm:container flex flex-col">
      <h3 class="max-w-3xl mx-auto" :class="{ 'text-br-green-light': backgroundBlack }">{{ heading }}</h3>
      <div class="text-copy my-4 max-w-3xl mx-auto" v-html="body"></div>

      <div class="mx-auto w-full max-w-md mt-8">
        <FormSelectInput
          v-model="currentCompany"
          :label="$t('insurance.placeholder')"
          :options="mappedCompanyList"
          :show-label="false"
        />
      </div>

      <div class="max-w-md w-full lg:flex lg:justify-center lg:max-w-3xl mx-auto text-black lg:space-x-8 mt-4 lg:mt-8">
        <Bubble
          :before-label="$t('insurance.upto')"
          :after-label="$t('insurance.percentTitle')"
          :is-dark-theme="backgroundBlack"
          :percentage="percentage"
          >{{ $t('insurance.percentage', { percent: percentage ? percentage.toFixed(0) : 0 }) }}</Bubble
        >
        <Bubble
          v-if="totalCoursePrice !== false"
          :after-label="$t('insurance.fixedTitle')"
          :is-dark-theme="backgroundBlack"
          :percentage="reimbursementValuePercentage"
          >{{
            $n(reimbursementValue ? reimbursementValue : 0, {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0
            })
          }}</Bubble
        >

        <Bubble
          v-if="totalCoursePrice !== false"
          :after-label="$t('insurance.totalCoursePrice')"
          :is-dark-theme="backgroundBlack"
          :percentage="totalCoursePricePercentage"
          >{{
            $n(totalCoursePrice, {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0
            })
          }}</Bubble
        >
      </div>

      <div v-if="cta" class="mx-auto text-center">
        <LinkField v-bind="cta" class="btn-pill-black mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import LinkField from '~/components/common/LinkField'
import Bubble from '@/components/generic/InsuranceReimbursement/Bubble.vue'
import FormSelectInput from '@/components/account/FormSelectInput.vue'

export default {
  name: 'InsuranceReimbursement',
  components: {
    LinkField,
    Bubble,
    FormSelectInput
  },
  props: {
    heading: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    },
    cta: {
      type: Object,
      default: () => {}
    },
    backgroundBlack: {
      type: Boolean,
      default: true
    },
    companyList: {
      type: Array,
      default: () => []
    },
    product: {
      type: Object,
      default: () => {}
    },
    layout: {
      type: String,
      default: 'fullWidth',
      validator: (value) => ['fullWidth', 'contained'].includes(value)
    }
  },

  data() {
    return {
      currentCompany: null,
      tweened: 0,
      fixedTweened: 0,
      animationFrame: null
    }
  },

  computed: {
    mappedCompanyList() {
      return this.companyList[0]?.companiesList
        ?.map((company) => ({
          label: company.companyName.trim(),
          value: company.companyName
        }))
        .sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        })
    },

    selectedCompany() {
      return this.companyList[0]?.companiesList?.find((company) => company.companyName === this.currentCompany)
    },

    percentage() {
      return this.selectedCompany?.reimbursementInPercentage
    },

    reimbursementValue() {
      if (this.coursePrice) {
        const reimbursementValue = Math.round(
          this.coursePrice.amount * (this.selectedCompany?.reimbursementInPercentage / 100)
        )

        return this.selectedCompany?.reimbursementInEur
          ? Math.min(this.selectedCompany?.reimbursementInEur, reimbursementValue)
          : reimbursementValue
      } else {
        return false
      }
    },

    reimbursementValuePercentage() {
      return (this.reimbursementValue / this.coursePrice.amount) * 100
    },

    coursePrice() {
      if (this.product?.variants) {
        return this.product.variants.edges[0].node.price
      } else {
        return false
      }
    },

    totalCoursePrice() {
      if (this.coursePrice) {
        return Math.max(0, this.coursePrice.amount - this.reimbursementValue)
      }

      return false
    },

    totalCoursePricePercentage() {
      return (this.totalCoursePrice / this.coursePrice.amount) * 100
    }
  },

  created() {
    this.currentCompany = this.mappedCompanyList[0].value
  }
}
</script>
