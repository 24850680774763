<template>
  <ProductProvider
    v-if="handle"
    v-slot="{
      addToCartDisabled,
      addToCart,
      product,
      firstVariant,
      price,
      compareAtPrice,
      priceRangeMin,
      priceRangeMax,
    }"
    :handle="handle"
  >
    <div
      v-if="product"
      v-track:view.item="gtmData(product)"
      class="sm:grid-cols-2 my-l container grid items-center content-center grid-cols-1"
    >
      <nuxt-link class="justify-self-center" :to="$localeRootPath('/products/' + handle)">
        <CldnImg
          v-if="firstVariant && firstVariant.image"
          :src="firstVariant.image.src"
          :alt="firstVariant.image.altText"
          crop="fit"
          sizes="(min-width: 1440px) 554px,(min-width: 1024px) 45vw, 100vw"
          data-testid="variantImage"
        />
      </nuxt-link>
      <div v-if="product && product.title" class="flex flex-col justify-center">
        <span class="fluid-xs leading-tightest text-br-green">
          {{ $t('shop.customersAlsoBought') }}
        </span>
        <div class="title font-semibold fluid-3xl leading-tightest my-3 uppercase">
          {{ product.title }}
        </div>
        <ProductComparePrice
          :compare-at-price="compareAtPrice"
          :price="price"
          :price-range-max="priceRangeMax"
          :price-range-min="priceRangeMin"
          price-class="fluid-xl leading-tight font-medium"
          compare-price-class="fluid-lg leading-tight font-medium sm:ml-3 font-light"
        />
        <div class="mt-5">
          <button
            :class="{ 'btn-pill-gray pointer-events-none': addToCartDisabled }"
            :disabled="addToCartDisabled"
            class="btn-pill-green whitespace-nowrap mr-1.5"
            data-testid="btnAddToCart"
            @click="addToCart"
          >
            {{ $t('cart.addToCart') }}
          </button>
          <nuxt-link :to="$localeRootPath('/products/' + handle)">
            <button
              v-track:click.item="gtmData(product)"
              class="btn-pill-white whitespace-nowrap mt-2"
              data-testid="btnViewProduct"
            >
              {{ $t('shop.viewProduct') }}
            </button>
          </nuxt-link>
        </div>
      </div>
    </div>
  </ProductProvider>
</template>

<script>
import gql from 'graphql-tag';
import CldnImg from '@/components/CldnImg';
import ProductProvider from '@/components/common/ProductProvider';
import ProductComparePrice from '@/components/common/ProductComparePrice';

export const ProductBlocksAlsoBoughtFragment = gql`
  fragment ProductBlocksAlsoBoughtFragment on productBlocks_alsoBought_BlockType {
    typeHandle
  }
`;

export default {
  components: {
    ProductProvider,
    ProductComparePrice,
    CldnImg,
  },
  props: {
    handle: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    gtmData(payload) {
      try {
        const variant = payload.variants?.edges[0].node;
        const itemSKU = variant.sku;

        return {
          item_list_id: [this.$route?.name, 'AlsoBought', payload.title.toLowerCase().replace(/ /g, '-')].join(' / '),
          item_list_name: [this.$route?.name, 'AlsoBought', payload.title].join(' / '),
          item_id: itemSKU,
          price: variant.price.amount,
          currency: variant.price.currency,
          item_category: payload.productType,
          item_name: payload.title,
        };
      } catch (e) {
        return {};
      }
    },
  },
};
</script>

<style scoped>
.title:first-letter {
  @apply uppercase;
}
</style>
