<template>
  <div v-if="iframeCode" :class="{ container: isContained }" v-html="iframeCode"></div>
</template>

<script>
export default {
  props: {
    iframeCode: {
      type: String,
      default: null
    },
    isContained: {
      type: Boolean,
      default: false
    }
  }
}
</script>
