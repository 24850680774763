import { render, staticRenderFns } from "./MediaText.vue?vue&type=template&id=466a8154"
import script from "./MediaText.vue?vue&type=script&lang=js"
export * from "./MediaText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports