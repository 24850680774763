import gql from 'graphql-tag'
import { EntryPageFragment } from '@/mixins/entryPage'

export const ProductImageByHandleQuery = gql`
  query ProductPriceByHandleQuery($handle: String!) {
    productByHandle(handle: $handle) {
      variants(first: 1) {
        edges {
          node {
            image {
              id
              src: originalSrc
            }
          }
        }
      }
    }
  }
`
const EntriesByProductQuery = gql`
  query EntriesByProductQuery($site: String!, $handles: [QueryArgument!]) {
    entries(section: "premiumTrainings", site: [$site], shopifyHandle: $handles) {
      ...EntryPageFragment
      title
      uri
      ... on premiumTrainings_training_Entry {
        shopifyHandle
      }
    }
  }
  ${EntryPageFragment}
`

const EntryByProductQuery = gql`
  query EntriesByProductQuery($site: String!, $handle: QueryArgument!) {
    entry(section: "premiumTrainings", site: [$site], shopifyHandle: [$handle]) {
      ...EntryPageFragment
      title
      uri
      ... on premiumTrainings_training_Entry {
        shopifyHandle
      }
    }
  }
  ${EntryPageFragment}
`

export default {
  data() {
    return {
      userTrainings: []
    }
  },
  methods: {
    async fetchUserTrainings() {
      if (this.userProducts?.length === 0) return
      try {
        const { entries } = await this.$craftRequest(EntriesByProductQuery, {
          site: this.$i18n.locale,
          handles: this.userProducts
        })
        const tranformed = await Promise.all(
          entries.map(async (entry) => {
            const handle = entry.shopifyHandle
            const image = await this.fetchProduct(handle)
            return {
              title: entry.title,
              cta: this.convertCta(entry.uri),
              shopifyHandle: handle,
              image: this.convertImage(image)
            }
          })
        )
        return tranformed
      } catch (e) {
        console.error(e)
      }
    },

    async fetchUserTrainingByHandle(handle) {
      try {
        const { entry } = await this.$craftRequest(EntryByProductQuery, {
          site: this.$i18n.locale,
          handle
        })

        return {
          title: entry.title,
          cta: this.convertCta(entry.uri),
          shopifyHandle: handle
        }
      } catch (e) {
        console.error(e)
      }
    },

    fetchProduct(handle) {
      return this.$shopifyRequest(ProductImageByHandleQuery, { handle })
        .then((data) => {
          return data.productByHandle.variants.edges[0].node.image.src
        })
        .catch((e) => console.error('error fetching Product from shopify', { handle, e }))
    },
    convertCta(uri) {
      return {
        type: 'entry',
        url: uri,
        element: {
          uri
        }
      }
    },
    convertImage(image) {
      return [
        {
          url: image
        }
      ]
    }
  }
}
