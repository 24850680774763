import { GraphQLClient } from 'graphql-request'
import { print } from 'graphql'

export default function (ctx, inject) {
  const graphql = async function (endPoint, query, variables = {}, headers = {}) {
    const stringyfiedQuery = typeof query === 'object' && query.kind === 'Document' ? print(query) : query

    const client = new GraphQLClient(endPoint, {
      headers: {
        Accept: 'application/json',
        ...headers
      }
    })

    try {
      return await client.request(stringyfiedQuery, variables)
    } catch (error) {
      console.log('graphql request error', error)
    }
  }

  inject('graphql', graphql)
}
