<template>
  <Newsletter v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import Newsletter from '@/components/common/blocks/Newsletter'
export const MarketingBlocksNewsletterFragment = gql`
  fragment MarketingBlocksNewsletterFragment on marketingBlocks_newsletter_BlockType {
    id
    typeHandle
    heading
    background
  }
`

export default {
  components: {
    Newsletter
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    background: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.text-wrapper {
  @apply max-w-[28rem];
}
</style>
