<template>
  <mailchimp-subscribe
    :url="settings.mailchimpUrl"
    :user-id="settings.mailchimpUser"
    :list-id="settings.newsletterListId"
    @success="onSuccess"
  >
    <template #default="{ subscribe, setEmail, error, success, loading }">
      <form class="flex flex-col items-center space-y-6" @submit.prevent="subscribe">
        <div class="lg:h-14 relative flex items-center w-full h-12">
          <label for="email" class="fluid-sm tracking-wider font-medium absolute left-0 pl-10">{{ $t('email') }}</label>
          <input
            id="email"
            v-model="email"
            required
            class="focus:outline-none focus:border-gray-400 fluid-sm tracking-wider font-medium w-full h-full px-10 pl-24 bg-gray-100 border border-transparent rounded-full appearance-none"
            type="email"
            @input="setEmail($event.target.value)"
          />
        </div>
        <button :disabled="loading" type="submit" class="btn-pill-outline-black">{{ $t('submit') }}</button>
        <div class="fluid-sm tracking-wider font-medium">
          <div v-if="error" class="responseText" v-html="formatResponse(error)"></div>
          <div v-if="success">{{ $t('newsletterSignupSuccess') }}</div>
          <div v-if="loading">{{ $t('loading') }}</div>
        </div>
      </form>
    </template>
  </mailchimp-subscribe>
</template>
<script>
import MailchimpSubscribe from 'vue-mailchimp-subscribe'

export default {
  components: {
    MailchimpSubscribe
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    /**
     * receive listId from page-store
     */
    settings() {
      return {
        mailchimpUrl: this.$config.mailchimp.url,
        mailchimpUser: this.$config.mailchimp.user,
        newsletterListId: this.$store.state.page.newsLetterListId || ''
      }
    }
  },
  methods: {
    onSuccess() {
      this.email = ''
    },
    formatResponse(data) {
      // B2C_COM-SHOP
      return data.replace(/[\s]B2C_([^\s])+/g, '').replace(/[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g, '')
    }
  }
}
</script>
<style scoped>
/* iphone zoom hax */
input {
  font-size: 100%;
}
.responseText >>> a {
  @apply hidden;
}
</style>
