<template>
  <ImageTitleFeature v-bind="$props" />
</template>
<script>
import gql from 'graphql-tag';
import ImageTitleFeature from '@/components/common/blocks/ImageTitleFeature';
import { LinkFragment } from '@/components/common/LinkField';
import { StoryPreviewFragment } from '@/components/stories/StoryPreview';
export const MarketingPageBlocksSmallStoryFeaturesWithBars = gql`
  fragment MarketingPageBlocksSmallStoryFeaturesWithBars on marketingBlocks_smallStoryFeaturesWithBars_BlockType {
    typeHandle
    heading
    cta {
      ...LinkFragment
    }
    imageTitlefeatures: marketingFeaturedStory {
      ... on marketingFeaturedStory_BlockType {
        id
        heading
        image {
          url
          title
        }
        alt
        page: cta {
          ...LinkFragment
        }
        story {
          uri
          ...StoryPreviewFragment
        }
      }
    }
  }
  ${LinkFragment}
  ${StoryPreviewFragment}
`;
export default {
  components: { ImageTitleFeature },
  props: {
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: () => {},
    },
    imageTitlefeatures: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
