import gql from 'graphql-tag'
import { LinkFragment } from '@/components/common/LinkField'

export const MarketingBlocksInsuranceReimbursementFragment = gql`
  fragment MarketingBlocksInsuranceReimbursementFragment on marketingBlocks_insuranceReimbursement_BlockType {
    typeHandle
    heading
    body
    layout
    cta {
      ...LinkFragment
    }
    backgroundBlack
    companyList {
      ... on insuranceReimbursement_insuranceCompanies_Entry {
        title
        companiesList {
          ... on companiesList_BlockType {
            companyName
            reimbursementInPercentage
            reimbursementInEur
          }
        }
      }
    }
  }
  ${LinkFragment}
`

export const EducationBlocksInsuranceReimbursementFragment = gql`
  fragment EducationBlocksInsuranceReimbursementFragment on educationBlocks_insuranceReimbursement_BlockType {
    typeHandle
    heading
    body
    layout
    cta {
      ...LinkFragment
    }
    backgroundBlack
    companyList {
      ... on insuranceReimbursement_insuranceCompanies_Entry {
        title
        companiesList {
          ... on companiesList_BlockType {
            companyName
            reimbursementInPercentage
            reimbursementInEur
          }
        }
      }
    }
  }
  ${LinkFragment}
`

export const ProductBlocksInsuranceReimbursementFragment = gql`
  fragment ProductBlocksInsuranceReimbursementFragment on productBlocks_insuranceReimbursement_BlockType {
    typeHandle
    heading
    body
    layout
    cta {
      ...LinkFragment
    }
    backgroundBlack
    companyList {
      ... on insuranceReimbursement_insuranceCompanies_Entry {
        title
        companiesList {
          ... on companiesList_BlockType {
            companyName
            reimbursementInPercentage
            reimbursementInEur
          }
        }
      }
    }
  }
  ${LinkFragment}
`

export const ArticleBlocksInsuranceReimbursementFragment = gql`
  fragment ArticleBlocksInsuranceReimbursementFragment on articleBlocks_insuranceReimbursement_BlockType {
    typeHandle
    heading
    body
    layout
    cta {
      ...LinkFragment
    }
    backgroundBlack
    companyList {
      ... on insuranceReimbursement_insuranceCompanies_Entry {
        title
        companiesList {
          ... on companiesList_BlockType {
            companyName
            reimbursementInPercentage
            reimbursementInEur
          }
        }
      }
    }
  }
  ${LinkFragment}
`
