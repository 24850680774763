import gql from 'graphql-tag'
import { LinkFragment } from '~/components/common/LinkField'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const MarketingBlocksFullWidthHeadingCtaFragment = gql`
  fragment MarketingBlocksFullWidthHeadingCtaFragment on marketingBlocks_fullWidthHeadingCta_BlockType {
    typeHandle
    heading
    video {
      ...CraftAssetFragment
    }
    cta {
      ...LinkFragment
    }
    buttonPrimary
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`
export const AboutBlockFullWidthHeadingFragment = gql`
  fragment AboutBlockFullWidthHeadingFragment on aboutBlocks_fullWidthHeading_BlockType {
    typeHandle
    heading
    video {
      ...CraftAssetFragment
    }
    cta {
      ...LinkFragment
    }
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`
