import gql from 'graphql-tag'

export const EducationBlocksCourseContentFragment = gql`
  fragment EducationBlocksCourseContentFragment on educationBlocks_courseContent_BlockType {
    typeHandle
    course
    backgroundGreen
  }
`

export const ProductBlocksCourseContentFragment = gql`
  fragment ProductBlocksCourseContentFragment on productBlocks_courseContent_BlockType {
    typeHandle
    course
    backgroundGreen
  }
`

export const MarketingBlocksCourseContentFragment = gql`
  fragment MarketingBlocksCourseContentFragment on marketingBlocks_courseContent_BlockType {
    typeHandle
    course
    backgroundGreen
  }
`

export const CourseContentQuery = gql`
  query CourseContentQuery($handle: String!, $language: String!) {
    learnWorldsCourse(handle: $handle, language: $language) {
      created
      updated
      language
      handle
      courseID
      title
      description
      authorName
      authorImage
      courseImage

      sections {
        created
        updated
        sectionId
        sectionTitle
        sectionDescription
        sectionAccess
        unitSummary {
          key
          count
        }
      }
    }
  }
`
