import gql from 'graphql-tag'

export const MarketingBlocksProductReviewsFragment = gql`
  fragment MarketingBlocksProductReviewsFragment on marketingBlocks_productReviews_BlockType {
    typeHandle
    shopifyHandle
  }
`
export const EducationBlocksProductReviewsFragment = gql`
  fragment EducationBlocksProductReviewsFragment on educationBlocks_productReviews_BlockType {
    typeHandle
    shopifyHandle
  }
`
