import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';

// transform site variable to match craft cms site handle
export const transformSiteVariable = function (variables) {
  if (!variables) {
    return {};
  }

  const map = {
    'ch-fr': 'chFr',
    'ch-de': 'ch',
  };

  if (variables.uri) {
    // check for each mapped locale if the uri contains language prefix
    for (const [key, value] of Object.entries(map)) {
      if (variables.uri.startsWith(`/${key}/`)) {
        variables.uri = variables.uri.replace(`/${key}/`, `/${value}/`);
        break;
      }
    }
  }

  if (variables.site && map[variables.site]) {
    variables.site = map[variables.site];
  }

  return variables;
};

export const sanitizeToken = (token) => {
  if (!token) return undefined;
  if (token.length === 0) return undefined;
  if (token.includes('?')) return undefined;
  return token;
};

/**
 * handles graphql requests to craft
 * handles bearer authentication
 * forwards url query preview token for craft-cms live editing preview
 */
export default function (ctx, inject) {
  const { env, route } = ctx;

  let url = env.cmsHttpEndpoint;

  const token = sanitizeToken(route.query.token);
  const preview = route.query['x-craft-live-preview'];
  const vercelNoCache = route.query._vercel_no_cache === '1';
  const options = {};

  if (token || preview || vercelNoCache) {
    url = env.cmsAdminEndpoint + '?token=' + token;
    options.headers = {
      Authorization: `Bearer ${ctx.env.cmsAuthToken}`,
    };
  }

  const client = new GraphQLClient(url, options);

  // sanitize gql to graphql-string
  const request = (query, variables) => {
    const stringyfiedQuery = typeof query === 'object' && query.kind === 'Document' ? print(query) : query;
    return client.request(stringyfiedQuery, transformSiteVariable(variables));
  };
  inject('craftRequest', request);
}
