import gql from 'graphql-tag'
import { CraftAssetFragment } from '@/components/common/CraftAsset'

export const AboutBlocksFullWidthAutoplayVideoFragment = gql`
  fragment AboutBlocksFullWidthAutoplayVideoFragment on aboutBlocks_fullWidthAutoplayVideo_BlockType {
    typeHandle
    preview {
      ...CraftAssetFragment
    }
    video {
      ...CraftAssetFragment
    }
    textOverlay
  }
  ${CraftAssetFragment}
`
export const MarketingBlocksFullWidthAutoplayVideoFragment = gql`
  fragment MarketingBlocksFullWidthAutoplayVideoFragment on marketingBlocks_fullWidthAutoplayVideo_BlockType {
    typeHandle
    preview {
      ...CraftAssetFragment
    }
    video {
      ...CraftAssetFragment
    }
    textOverlay: heading
  }
  ${CraftAssetFragment}
`
