import gql from 'graphql-tag'
import { LinkFragment } from '~/components/common/LinkField'

export const ProductBlocksTeaserWithCtaFragment = gql`
  fragment ProductBlocksTeaserWithCtaFragment on productBlocks_teaserWithCta_BlockType {
    typeHandle
    heading
    body
    image {
      url
      title
    }
    cta {
      ...LinkFragment
    }
    backgroundGray
  }
  ${LinkFragment}
`
