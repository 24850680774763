<template>
  <div>
    <ProductPriceLine v-if="price" class="my-4" :price="price" :compare-price="comparePrice" />

    <div class="mt-4">
      <CtaButton :disabled="!isAvailable" :loading="isLoading" @click.native="$emit('addToCart')">{{
        $t('cart.addToCart')
      }}</CtaButton>
    </div>
  </div>
</template>

<script>
import ProductPriceLine from '@/components/shop/products/ProductPriceLine'
import CtaButton from '@/components/common/CtaButton'

export default {
  components: {
    ProductPriceLine,
    CtaButton
  },

  props: {
    price: {
      type: Object,
      default: () => {}
    },
    comparePrice: {
      type: Object,
      default: () => {}
    },
    isAvailable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
