import { variantOnSale } from '@/components/shop/products/Variant'

export const formatRating = (ratingValue) => (ratingValue ? Number(ratingValue).toFixed(1) : null)

/**
 * EEC representation of product
 */
export const buildEECProduct = (variant, product, quantity, rating = null) => ({
  name: product.title,
  id: variant.sku,
  price: `${variant.price?.amount || variant.price}`,
  brand: variantOnSale(variant),
  category: product.productType,
  variant: variant.title,
  quantity,
  dimension16: formatRating(rating)
})
