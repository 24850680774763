<template>
  <CopyText v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import { EntryReferenceFragment } from '@/mixins/entryPage'
import CopyText from '@/components/common/blocks/CopyText'

export const MarketingBlocksCopyTextFragment = gql`
  fragment MarketingBlocksCopyTextFragment on marketingBlocks_copyText_BlockType {
    typeHandle
    id
    body
    relatedImage {
      url
      title
    }
    related {
      ...EntryReferenceFragment
    }
    relatedCaption
  }
  ${EntryReferenceFragment}
`

export default {
  components: {
    CopyText
  },
  props: {
    body: {
      type: String,
      default: ''
    },
    relatedCaption: {
      type: String,
      default: ''
    },
    related: {
      type: Array,
      default: () => []
    },
    relatedImage: {
      type: Array,
      default: () => []
    }
  }
}
</script>
