import gql from 'graphql-tag'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const ExercisesBlocksVideoWithDescriptionFragment = gql`
  fragment ExercisesBlocksVideoWithDescriptionFragment on exercisesBlocks_videoWithDescription_BlockType {
    typeHandle
    heading
    description
    preview {
      ...CraftAssetFragment
    }
    video {
      ...CraftAssetFragment
    }
    textOverlay
    autoplay
    position
    backgroundGray
    centered
  }
  ${CraftAssetFragment}
`
export const ProductBlocksVideoWithDescriptionFragment = gql`
  fragment ProductBlocksVideoWithDescriptionFragment on productBlocks_videoWithDescription_BlockType {
    typeHandle
    heading
    description
    preview {
      ...CraftAssetFragment
    }
    video {
      ...CraftAssetFragment
    }
    textOverlay
    autoplay
    backgroundGray
    centered
  }
  ${CraftAssetFragment}
`
export const EducationBlocksVideoWithDescriptionFragment = gql`
  fragment EducationBlocksVideoWithDescriptionFragment on educationBlocks_videoWithDescription_BlockType {
    typeHandle
    heading
    description
    preview {
      ...CraftAssetFragment
    }
    video {
      ...CraftAssetFragment
    }
    textOverlay
    autoplay
    backgroundGray
    centered
  }
  ${CraftAssetFragment}
`
