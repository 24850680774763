<template>
  <div class="bg-putty">
    <div class="container relative pt-5 md:p-12" :class="{ 'md:pb-0': !expanderDisabled }">
      <component
        :is="expanderDisabled ? 'div' : 'Expandable'"
        :open="isExpanded"
        :duration="500"
        :minimal-height="cutOffPoint"
        :disable="expanderDisabled"
        :use-opacity="false"
      >
        <div class="md:flex-nowrap flex flex-row flex-wrap">
          <div
            ref="description"
            class="md:order-1 order-2 md:py-4 md:pr-4 lg:py-12 lg:pr-12 md:pb-0 md:w-2/3 checkmark-bullets checkmark-noborder"
          >
            <div
              v-if="description"
              class="fluid-base leading-relaxed font-light text-description"
              v-html="description"
            ></div>
          </div>
          <div class="md:order-2 md:w-1/3 order-1">
            <div ref="keyFeatures" class="short-column md:border-l md:py-4 md:pl-4 lg:py-12 lg:pl-12 border-gray-400">
              <div
                v-if="features"
                class="fluid-base leading-relaxed font-light checkmark-bullets checkmark-noborder text-description pb-8 md:pb-0"
              >
                <ul class="mb-0">
                  <li v-for="(feature, idx) in features" :key="idx">{{ feature }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </component>
      <div
        v-if="!isExpanded && !expanderDisabled"
        class="gradient bg-gradient-to-t from-putty absolute bottom-0 w-full h-20"
      />
    </div>
    <div v-if="!expanderDisabled" class="container">
      <div class="expand text-br-green md:px-12 py-6 md:pb-12 order-3 cursor-pointer" @click="expand()">
        {{ $t(!isExpanded ? 'showMore' : 'showLess') }}
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Expandable from '@/components/common/Expandable';

export const ProductBlocksProductInformationFragment = gql`
  fragment ProductBlocksProductInformationFragment on productBlocks_productInformation_BlockType {
    typeHandle
  }
`;

export default {
  components: {
    Expandable,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    features: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isExpanded: false,
      cutOffPoint: 500,
      expanderDisabled: false,
      descriptionNode: null,
      keyFeaturesNode: null,
      pageBreakNode: null,
      windowWidth: null,
    };
  },
  mounted() {
    this.descriptionNode = this.$refs.description;
    // todo: get page-break element by id or classname once pim delivers
    this.pageBreakNode = this.descriptionNode.querySelector('div[style="page-break-after:always"]');
    this.keyFeaturesNode = this.$refs.keyFeatures;

    if (this.descriptionNode.textContent?.length < 1000) {
      this.expanderDisabled = true;
    }

    this.adjustCols();
    window.addEventListener('resize', this.adjustCols);
  },
  methods: {
    adjustCols() {
      if (!this.windowWidth) {
        this.windowWidth = window.innerWidth;
      } else if (this.windowWidth === window.innerWidth) {
        // prevent unnecessary re-rendering, since width is not changed
        return;
      }

      /*
      * Desktop Version:
      * Default is the height of the left text is 120% of the width of the left text
      * When there are key features that are longer as the body text on the left, then we expand the left
      *   column and do not collapse (key features expand the whole container)
      * If the left text is longer than above defined default, then collapse position is defined by a page break.

      * Mobile Version:
      * Key features are not collapsed, always expanded
      * Default behaviour: we collapse main description when it exceeds 85% of the view port height
      * page break will not be considered on mobile and tablet
      * */

      const marginOffset = 55;
      const rightColumnHeight = this.keyFeaturesNode.getBoundingClientRect().height;
      const leftColumnHeight = this.descriptionNode.getBoundingClientRect().height;
      const leftColumnWidth = this.descriptionNode.getBoundingClientRect().width;
      const pageBreakPosition = this.pageBreakNode?.offsetTop || leftColumnWidth * 1.2;

      if (window.innerWidth >= 768) {
        if (rightColumnHeight >= leftColumnHeight) {
          this.isExpanded = true;
          this.expanderDisabled = true;
        } else {
          this.cutOffPoint = pageBreakPosition - marginOffset;
        }
      } else {
        const screenHeight = window.innerHeight * 0.85;
        this.cutOffPoint = rightColumnHeight > screenHeight ? rightColumnHeight : screenHeight - marginOffset;
      }
    },
    expand() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) this.pageBreakNode?.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
