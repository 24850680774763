<template>
  <ProductProvider v-slot="{ product, tsProduct }" :handle="shopifyHandle">
    <ConversionAreaOne :is-adjusted="isAdjusted" :product="product" :ts-product="tsProduct" />
  </ProductProvider>
</template>

<script>
import ConversionAreaOne from '~/components/shop/products/productBlocks/ConversionAreaOne/ConversionAreaOne'
import ProductProvider from '@/components/common/ProductProvider'

export default {
  components: {
    ConversionAreaOne,
    ProductProvider
  },
  props: {
    shopifyHandle: {
      type: String,
      default: null
    },
    isAdjusted: {
      type: Boolean,
      default: false
    }
  }
}
</script>
