import Client from 'shopify-buy/index.unoptimized.umd'

/**
 * build encoded store-front variantId from admin-copied variantid
 * @param {String} variantId
 * @return {String} encoded variantId
 */
export const storeFrontVariantId = (variantId) =>
  Buffer.from(`gid://shopify/ProductVariant/${variantId}`).toString('base64')

export default (ctx, inject) => {
  const {
    app: {
      i18n: { locale, locales }
    }
  } = ctx

  const { shopifyApiAccessToken, shopifyApiDomain } = locales.find((l) => l.code === locale)

  const config = {
    domain: shopifyApiDomain,
    storefrontAccessToken: shopifyApiAccessToken,
    language: locale
  }

  /**
   * @param {String} variantid - shopify variant id copied from admin
   * @return {Promise} - resolves to shopify checkoutUrl or null if checkout could not be created
   */
  const educationCheckoutUrl = async (variantId) => {
    const lineItems = [{ variantId: storeFrontVariantId(variantId), quantity: 1 }]
    try {
      const client = Client.buildClient(config)
      let checkout = await client.checkout.create()
      checkout = await client.checkout.addLineItems(checkout.id, lineItems)
      if (checkout.lineItems.length === 0) throw new Error('Education could not be added to checkout')
      return checkout.webUrl
    } catch (error) {
      console.error(error)
      ctx.$sentry.captureException(error)
      return null
    }
  }

  inject('educationCheckoutUrl', educationCheckoutUrl)
}
