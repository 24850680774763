import gql from 'graphql-tag';
import { LinkFragment } from '@/components/common/LinkField';

export const GlobalMarketingBarFragment = gql`
  fragment GlobalMarketingBarFragment on globalMarketingBar_GlobalSet {
    globallyEnabled

    marketingUspBlocks {
      ... on marketingUspBlocks_icon_BlockType {
        typeHandle
        svg {
          url
        }
      }
      ... on marketingUspBlocks_text_BlockType {
        typeHandle
        text
      }
      ... on marketingUspBlocks_countdown_BlockType {
        typeHandle
        dateTime
      }
      ... on marketingUspBlocks_cta_BlockType {
        typeHandle
        cta {
          ...LinkFragment
        }
      }
    }
  }
  ${LinkFragment}
`;

export const state = () => ({
  isOpen: false,
  blocks: [],
});

export const actions = {
  close({ commit }) {
    commit('CLOSE');
  },
};

export const mutations = {
  INIT(state, { globallyEnabled, marketingUspBlocks }) {
    state.blocks = marketingUspBlocks;
    state.isOpen = globallyEnabled;
  },
  CLOSE(state) {
    state.isOpen = false;
  },
};
