<template>
  <div class="container my-20 grid grid-cols-10 sm:gap-10">
    <div class="col-span-10 md:col-span-4 my-5 sm:my-0 mx-0 md:-mx-0">
      <div class="md:top-28 md:sticky relative box">
        <h2 v-if="heading" class="fluid-3xl leading-tightest my-8" data-test-id="heading" v-html="heading"></h2>
      </div>
    </div>
    <div
      v-if="captionedImages && captionedImages.length"
      class="col-span-10 md:col-span-6 grid grid-cols-1 md:grid-cols-2 gap-x-10 row-span-1"
    >
      <ImageWithCaption
        v-for="({ image, caption }, index) in captionedImages"
        :key="index"
        :image="image"
        :caption="caption"
        :class="index % 2 === 1 ? 'md:mt-10' : 'md:-mt-10 md:mb-10'"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ImageWithCaption from '@/components/marketing/marketingBlocks/ImageWithCaption'

export const MarketingBlocksFixedBoxImagesFragment = gql`
  fragment MarketingBlocksFixedBoxImagesFragment on marketingBlocks_fixedBoxImages_BlockType {
    typeHandle
    heading
    captionedImages {
      ... on captionedImages_BlockType {
        id
        image {
          url
          title
        }
        caption
      }
    }
  }
`
export default {
  name: 'FixedBoxImages',
  components: { ImageWithCaption },
  props: {
    heading: {
      type: String,
      default: ''
    },
    captionedImages: {
      type: Array,
      default: () => []
    }
  }
}
</script>
