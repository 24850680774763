import gql from 'graphql-tag'

export const MarketingBlocksFeatureListFragment = gql`
  fragment MarketingBlocksFeatureListFragment on marketingBlocks_featureList_BlockType {
    typeHandle
    heading
    points {
      ... on points_BlockType {
        point
      }
    }
  }
`

export const ProductBlocksFeatureListFragment = gql`
  fragment ProductBlocksFeatureListFragment on productBlocks_featureList_BlockType {
    typeHandle
    heading
    points {
      ... on points_BlockType {
        point
      }
    }
  }
`

export const EducationBlocksFeatureListFragment = gql`
  fragment EducationBlocksFeatureListFragment on educationBlocks_featureList_BlockType {
    typeHandle
    heading
    points {
      ... on points_BlockType {
        point
      }
    }
  }
`
