import qs from 'qs'
import isString from 'lodash/isString'

export const validObjectId = (objectID) => {
  if (isString(objectID) && objectID.length !== 0) return true
  if (typeof objectID === 'number' && objectID !== 0) return true
  return false
}

// export const objectFacets = ['products.title', 'bodyParts.title', 'painPoints.title', 'resourceTopics.title']
/**
 * filter-show-state from uri
 * @param  {string} query
 * @returns {boolean}
 */
export const isFiltersQuery = (query) => Object.keys(query).some((paramKey) => ~paramKey.indexOf('refinementList'))

/**
 * Build facet-prerefinement-object for page-entry
 * @param  {object} prerefinements - object with shape of {products: {title: "blackroll"}}
 * @param {String} pageProfile
 * @returns {object} keys fascet title - values prerefined facet values
 */
export const prerefinementsByPageProfile = (prerefinements, pageProfile) => {
  if (!prerefinements || !pageProfile) return {}
  return exercisesFacets(pageProfile).reduce((acc, curr) => {
    const [obj, attr] = curr.split('.')
    if (obj && attr) {
      const filterValues = prerefinements[obj]?.map((o) => o[attr]) || []
      if (filterValues.length) acc[curr] = filterValues
    }
    return acc
  }, {})
}

/**
 * Maps Page-entry-facetProfile to list of facets
 * @param  {string} pageProfile - fixed values from api
 * @returns {array} list of facet-titles, defaults to []
 */
export const exercisesFacets = (pageProfile) => {
  const facetProfiles = {
    none: [],
    exerciseByProduct: ['products.title', 'bodyParts.title', 'difficulty', 'trainingGoals'],
    exerciseByBodyPart: ['bodyParts.title', 'products.title', 'difficulty', 'trainingGoals'],
    routineByPainPoint: ['painPoints.title'],
    routineBySport: ['sports.title', 'trainingGoals.title'],
    routineByTopic: ['routineTopics.title']
  }
  return facetProfiles[pageProfile] || facetProfiles.none
}

/**
 * map pageProfile to search reslut type
 * @param  {string} pageProfile
 * @returns {string} exercise|routine
 */
export const resultType = (pageProfile) => {
  const types = {
    none: null,
    exerciseByProduct: 'exercise',
    exerciseByBodyPart: 'exercise',
    routineByPainPoint: 'routine',
    routineBySport: 'routine',
    routineByTopic: 'routine'
  }
  return types[pageProfile] || types.none
}

/**
 * url-query generator conforms to algolia-js client library
 * generates query for prerefinements
 * @param  {object} entry - page-entry with pageProfile and prerefined facet values set
 * @returns {string} defaults to 'refinementlist' if no prerefinements given
 */
export const exercisesUrlQuery = (refinementList) => {
  const query = qs.stringify({ refinementList })
  return query !== '' ? query : 'refinementList'
}
