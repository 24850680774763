<template>
  <div class="bg-putty relative z-10">
    <NewsletterSignupForm v-bind="{ ...newsletterSignup }" :global-fallback="true" />
    <GlobalUspBar />
    <div class="bg-blackly text-white">
      <div class="">
        <div class="gap-y-8 container grid py-10">
          <FooterNav :nav="footerNav" />
          <div class="md:grid-cols-2 lg:grid-cols-4 gap-y-8 gap-x-8 md:text-left grid text-center">
            <PaymentMethods :payment-methods="paymentMethods" />
            <LogisticPartners :logistic-partners="logisticPartners" />
            <div>
              <div class="fluid-xs leading-snug uppercase font-medium mb-4">
                {{ $t('navigation.downloadOurApp') }}
              </div>
              <AppStoreLinks />
            </div>
            <SocialMediaAccounts :social-media-accounts="socialMediaAccounts" />
          </div>
        </div>
      </div>
    </div>
    <div class="md:flex container justify-between py-8">
      <div
        v-show="hasAlternates"
        class="fluid-xs leading-snug uppercase font-medium md:order-1 md:justify-end md:mb-0 flex items-center justify-center mb-2"
      >
        <RegionAndLanguageIndicator class="ml-1" />
      </div>
      <LegalNav class="text-blackly" :legal-nav="legalNav" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters } from 'vuex';
import FooterNav, { FooterNavFragment } from '@/components/layout/footer/FooterNav';
import PaymentMethods, { PaymentMethodsFragment } from '@/components/layout/footer/PaymentMethods';
import LogisticPartners, { LogisticPartnersFragment } from '@/components/layout/footer/LogisticPartners';
import SocialMediaAccounts, { SocialMediaAccountsFragment } from '@/components/layout/footer/SocialMediaAccounts';
import LegalNav, { LegalNavFragment } from '@/components/layout/footer/LegalNav';
import GlobalUspBar from '@/components/layout/GlobalUspBar';
import NewsletterSignupForm from '@/components/forms/NewsletterSignupForm';
import GlobalNewsletterSignupFormFragment from '@/components/forms/NewsletterSignupForm/GlobalNewsletterSignupFormFragment.gql';
import RegionAndLanguageIndicator from '@/components/layout/header/RegionAndLanguageIndicator';

const AppStoreLinks = () => import('@/components/common/AppStoreLinks');

export const FooterQuery = gql`
  query FooterQuery($site: String!) {
    footerNav: globalSet(site: [$site], handle: "footerNav") {
      ...FooterNavFragment
    }
    paymentMethods: globalSet(site: [$site], handle: "paymentMethods") {
      ...PaymentMethodsFragment
    }
    logisticPartners: globalSet(site: [$site], handle: "logisticPartners") {
      ...LogisticPartnersFragment
    }
    socialMediaAccounts: globalSet(site: [$site], handle: "socialMediaAccounts") {
      ...SocialMediaAccountsFragment
    }
    legalNav: globalSet(site: [$site], handle: "legalNav") {
      ...LegalNavFragment
    }
    newsletterSignup: globalSet(site: [$site], handle: "newsletterSignup") {
      ...GlobalNewsletterSignupFormFragment
    }
  }
  ${FooterNavFragment}
  ${PaymentMethodsFragment}
  ${LogisticPartnersFragment}
  ${SocialMediaAccountsFragment}
  ${LegalNavFragment}
  ${GlobalNewsletterSignupFormFragment}
`;
export default {
  components: {
    NewsletterSignupForm,
    FooterNav,
    PaymentMethods,
    LogisticPartners,
    SocialMediaAccounts,
    AppStoreLinks,
    LegalNav,
    GlobalUspBar,
    RegionAndLanguageIndicator,
  },
  data() {
    return {
      footerNav: null,
      paymentMethods: null,
      logisticPartners: null,
      socialMediaAccounts: null,
      legalNav: null,
      currentlyOpenPopover: null,
      newsletterSignup: null,
    };
  },

  async fetch() {
    await this.fetchFooter();
  },

  computed: {
    ...mapGetters('mainNav', ['hasAlternates']),
    currentLocale() {
      const { locale } = this.$i18n;
      return locale;
    },
    newsletterHeading() {
      return this.newsletterSignup?.simpleHeading;
    },
  },

  methods: {
    async fetchFooter() {
      try {
        const { footerNav, paymentMethods, logisticPartners, socialMediaAccounts, legalNav, newsletterSignup } =
          await this.$craftRequest(FooterQuery, {
            site: this.$i18n.locale,
          });
        this.footerNav = footerNav;
        this.paymentMethods = paymentMethods;
        this.logisticPartners = logisticPartners;
        this.socialMediaAccounts = socialMediaAccounts;
        this.legalNav = legalNav;
        this.newsletterSignup = newsletterSignup;
      } catch (e) {
        this.$sentry.captureException(e);
      }
    },
    togglePopover(popoverName) {
      if (this.currentlyOpenPopover === popoverName) {
        this.currentlyOpenPopover = null;
      } else {
        this.currentlyOpenPopover = popoverName;
      }
    },
    closePopover() {
      this.currentlyOpenPopover = null;
    },
  },
};
</script>
