<template>
  <div class="py-16" :class="{ 'bg-putty': backgroundGray }">
    <CardSlider :items="articles" :heading="heading" :breakpoints="breakpoints">
      <ArticlePreview
        v-for="(article, index) in articles"
        :key="article.id"
        v-track:view="gtmData(article, index)"
        v-track:click="gtmData(article, index)"
        :article="article"
        data-test-id="article"
      />
    </CardSlider>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import ArticlePreview, { ArticlePreviewFragment } from '@/components/article/ArticlePreview';
import CardSlider from '@/components/common/CardSlider';

export const AddonBlocksArticleRowSlideFragment = gql`
  fragment AddonBlocksArticleRowSlideFragment on addOnBlocks_articleRowSlide_BlockType {
    id
    typeHandle
    heading
    backgroundGray
    articles {
      ...ArticlePreviewFragment
    }
  }
  ${ArticlePreviewFragment}
`;

export const TopicBlocksArticleRowSlideFragment = gql`
  fragment TopicBlocksArticleRowSlideFragment on topicBlocks_articleRowSlide_BlockType {
    id
    typeHandle
    heading
    backgroundGray
    articles {
      ...ArticlePreviewFragment
    }
  }
  ${ArticlePreviewFragment}
`;

export const ExercisesBlocksArticleRowSlideFragment = gql`
  fragment ExercisesBlocksArticleRowSlideFragment on exercisesBlocks_articleRowSlide_BlockType {
    id
    typeHandle
    heading
    backgroundGray
    articles {
      ...ArticlePreviewFragment
    }
    position
  }
  ${ArticlePreviewFragment}
`;

export const MarketingBlocksArticleRowSlideFragment = gql`
  fragment MarketingBlocksArticleRowSlideFragment on marketingBlocks_articleRowSlide_BlockType {
    id
    typeHandle
    heading
    backgroundGray
    articles {
      ...ArticlePreviewFragment
    }
  }
  ${ArticlePreviewFragment}
`;

export const ArticleBlocksArticleRowSlideFragment = gql`
  fragment ArticleBlocksArticleRowSlideFragment on articleBlocks_articleRowSlide_BlockType {
    id
    typeHandle
    heading
    backgroundGray
    articles {
      ...ArticlePreviewFragment
    }
  }
  ${ArticlePreviewFragment}
`;

export default {
  name: 'ArticleRowSlide',
  components: {
    CardSlider,
    ArticlePreview,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    backgroundGray: {
      type: Boolean,
      default: true,
    },
    articles: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 700,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
        {
          minWindowWidth: 1000,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      ],
    };
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.uri,
        promotion_name: [this.$route?.name, payload.title].join(' / '),
        creative_name: 'articleRowSlide',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
