<template>
  <FeaturesRotation v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import FeaturesRotation from '@/components/common/FeaturesRotation'

export const MarketingPagesFeaturesRotationFragment = gql`
  fragment MarketingPagesFeaturesRotationFragment on marketingBlocks_featuresRotation_BlockType {
    typeHandle
    heading
    guestOnly
    featureCards: marketingFeaturesCards {
      ... on marketingFeaturesCards_BlockType {
        heading
        body
      }
    }
  }
`
export default {
  name: 'MarketingFeaturesRotation',
  components: { FeaturesRotation },
  props: {
    heading: {
      type: String,
      default: null
    },
    featureCards: {
      type: Array,
      default: () => []
    },
    guestOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
