import gql from 'graphql-tag';
import { CraftAssetFragment } from '~/components/common/CraftAsset';

export const ProductBlocksSplitContentSliderFragment = gql`
  fragment ProductBlocksSplitContentSliderFragment on productBlocks_splitContentSlider_BlockType {
    typeHandle
    appearance
    slides: productSplitContentSlides {
      ... on productSplitContentSlides_BlockType {
        id
        image {
          ...CraftAssetFragment
        }
        overlayImage {
          url
          title
        }
        heading
        body
        productAttributes {
          url
        }
      }
    }
  }
  ${CraftAssetFragment}
`;

export const MarketingBlocksSplitContentSliderFragment = gql`
  fragment MarketingBlocksSplitContentSliderFragment on marketingBlocks_splitContentSlider_BlockType {
    typeHandle
    appearance
    slides: marketingSplitContentSlides {
      ... on marketingSplitContentSlides_BlockType {
        id
        image {
          ...CraftAssetFragment
        }
        overlayImage {
          url
          title
        }
        heading
        body
        productAttributes {
          url
        }
      }
    }
  }
  ${CraftAssetFragment}
`;
