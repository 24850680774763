export default (ctx, inject) => {
  /**
   * fetches exercise from algolia
   * validates format of objectID - throws TypeError on wrong format
   * throws Error on 404
   *
   * @param {string} objectID
   * @returns {Promise} - object of algoila exercise
   */
  const productReviews = {
    buildUrl(tsid, sku) {
      return `https://cdn1.api.trustedshops.com/shops/${tsid}/products/skus/${this.toHex(
        sku
      )}/productreviewstickers/v1/reviews.json`
    },
    toHex(str) {
      let hex = null
      try {
        hex = unescape(encodeURIComponent(str))
          .split('')
          .map((v) => v.charCodeAt(0).toString(16))
          .join('')
      } catch (e) {
        console.error('invalid text input: ' + str)
      }
      return hex
    },
    fetchReviews(sku) {
      // get localized trusted-shops-id defined in nuxt.config.js
      const { $axios, app } = ctx
      const locale = app.i18n.locale
      const locales = app.i18n.locales
      const tsid = locales.find((l) => l.code === locale)?.tsid
      if (tsid && sku) {
        const tsUrl = this.buildUrl(tsid, sku)
        return $axios
          .$get(tsUrl, {
            timeout: 1500,
            transformRequest: (data, headers) => {
              delete headers.common.Authorization
              return data
            }
          })
          .then((res) => {
            try {
              return res.response.data.product
            } catch (e) {
              return null
            }
          })
          .catch(() => {
            console.log('no reviews found for ' + sku)
            return null
          })
      }
    }
  }
  inject('productReviews', productReviews)
}
