<template>
  <div class="py-20" :class="backgroundGreen ? 'bg-br-green-light' : 'bg-putty'">
    <div v-if="heading" class="container">
      <div class="max-w-screen-md mx-auto">
        <h2 class="fluid-3xl leading-tightest mb-4 text-center">{{ heading }}</h2>
      </div>
    </div>
    <div>
      <CommonAccordion v-for="(panel, index) in accordions" :key="index" :heading="panel.heading" :body="panel.body" />
    </div>
  </div>
</template>

<script>
import CommonAccordion from '@/components/common/Accordion'

export default {
  components: {
    CommonAccordion
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    backgroundGreen: {
      type: Boolean,
      default: false
    },
    accordions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false
    }
  }
}
</script>
