import { render, staticRenderFns } from "./VideoFeature.vue?vue&type=template&id=6df867de&scoped=true"
import script from "./VideoFeature.vue?vue&type=script&lang=js"
export * from "./VideoFeature.vue?vue&type=script&lang=js"
import style0 from "./VideoFeature.vue?vue&type=style&index=0&id=6df867de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df867de",
  null
  
)

export default component.exports