export const state = () => ({
  notification: {},
  routinesSlider: {
    heading: null,
    routines: [],
    active: true
  },
  productsSlider: {
    heading: null,
    products: [],
    active: true
  },
  loginDisabled: process.env.loginDisabled
})

export const mutations = {
  INIT(state, account) {
    state.notification = {
      heading: account.heading,
      active: account.onOffSwitch,
      description: account.description
    }
    state.routinesSlider = {
      routines: account.routines,
      active: account.recommendedOn,
      heading: account.routinesSliderHeading
    }
    state.productsSlider = {
      products: account.products,
      active: account.bestsellersOn,
      heading: account.productsSliderHeading
    }
  }
}

export const getters = {
  notification(state) {
    return state.notification
  },
  routinesSlider(state) {
    return state.routinesSlider
  },
  productsSlider(state) {
    return state.productsSlider
  }
}
