<template>
  <CommonnAccordionBlock
    v-if="accordionsTransformed.length"
    :heading="$t('shop.accordionHeading')"
    :accordions="accordionsTransformed"
    class="bg-br-green-light"
  />
</template>

<script>
import gql from 'graphql-tag'
import CommonnAccordionBlock from '@/components/common/blocks/AccordionBlock'

export const ProductBlocksAccordionFragment = gql`
  fragment ProductBlocksAccordionFragment on productBlocks_accordion_BlockType {
    typeHandle
  }
`

export default {
  components: { CommonnAccordionBlock },
  props: {
    accordions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    accordionsTransformed() {
      return (
        this.accordions?.map((f) => ({
          heading: f.title,
          body: f.description
        })) || []
      )
    }
  }
}
</script>
