export const state = () => ({
  breadCrumb: [],
  sectionHandle: null,
  newsLetterListId: null
})

export const actions = {
  setBreadCrumb({ commit }, crumbs) {
    commit('SET_BREADCRUMB', crumbs)
  },
  setSectionHandle({ commit, dispatch }, sectionHandle) {
    commit('SET_SECTION_HANDLE', sectionHandle)
    dispatch('setNewsletterList', sectionHandle)
  },
  /**
   * set newsletter list by locale
   * overwrite for academy section to acacemy-list-de
   */
  setNewsletterList({ commit }, sectionHandle) {
    let listId
    if (sectionHandle === 'academy') {
      listId = this.$config.academy.newsletterListId
    } else {
      const currentLocaleSettings = this.$i18n.locales.find((l) => l.code === this.$i18n.locale)
      listId = currentLocaleSettings.newsletterListId
    }
    commit('SET_NEWSLETTER_LIST', listId)
  },
  reset({ commit }) {
    commit('SET_BREADCRUMB', [])
  }
}
export const mutations = {
  SET_BREADCRUMB(state, crumbs) {
    state.breadCrumb = crumbs
  },
  SET_SECTION_HANDLE(state, sectionHandle) {
    state.sectionHandle = sectionHandle
  },
  SET_NEWSLETTER_LIST(state, listId) {
    state.newsLetterListId = listId
  }
}

export const getters = {
  breadCrumb(state) {
    return state.breadCrumb
  }
}
