const processOWRedirect = (redirect, { $auth, store, $localeRootPath }) => {
  if (redirect === 'checkout') {
    store
      .dispatch('cart/checkoutUrl')
      .then((url) => {
        window.localStorage.removeItem('ow:redirect')
        window.open(url, '_self')
      })
      .catch((e) => {
        console.warn('could not login to shopify')
        return false
      })

    return $localeRootPath('/processing')
  } else if (redirect === 'learnworlds') {
    const redirectUrl = window.localStorage.getItem('ow:redirectUrl')

    window.localStorage.removeItem('ow:redirect')
    window.localStorage.removeItem('ow:redirectUrl')

    $auth.strategies.oneworld
      .getLearnworldsSSO(null, redirectUrl)
      .then((response) => {
        if (response.data.data.learnWorldsLogin.success) {
          window.open(response.data.data.learnWorldsLogin.url, '_self')
        }
      })
      .catch((e) => {
        console.warn('could not login to learnworlds')
        return false
      })

    return $localeRootPath('/processing')
  }
}

export default function (ctx, inject) {
  const { $auth, $localeRootPath } = ctx
  $auth.onRedirect((to) => {
    if (typeof window !== 'undefined') {
      // check if we have fromUri param
      // this enables us to redirect to the previous page
      const fromUri = window.localStorage.getItem('fromUri')

      if (fromUri) {
        window.localStorage.removeItem('fromUri')

        if (fromUri.includes(window.location.origin)) {
          return fromUri.replace(window.location.origin, '')
        }

        return fromUri
      }

      // check if we have a redirect flag in localStorage
      const redirect = window.localStorage.getItem('ow:redirect')
      if (redirect) {
        const result = processOWRedirect(redirect, ctx)

        if (result !== false) {
          return result
        }
      }
    }

    return $localeRootPath(to)
  })

  inject('sso', {
    processOWRedirect
  })
}
