<template>
  <div v-if="testimonial" class="bg-putty">
    <div class="lg:grid-cols-2 py-14 lg:container lg:py-12 lg:px-0 grid items-center content-center grid-cols-1 px-4">
      <FixedAspect :ratio="0.76" class="sm:block lg:hidden order-first object-cover w-full">
        <CldnImg v-if="imageSrc" :src="imageSrc" crop="fill" :ratio="0.76" sizes="100vw" class="object-cover" />
      </FixedAspect>
      <FixedAspect :ratio="1.18" class="expert-picture lg:block order-last hidden w-full h-full">
        <CldnImg v-if="imageSrc" :src="imageSrc" crop="fill" :ratio="1.18" sizes="(min-width: 1440px) 648px, 50vw" />
      </FixedAspect>
      <div class="bg-blackly lg:py-52 lg:px-16 lg:h-full lg:flex lg:flex-col lg:justify-center px-8 pt-10 pb-20">
        <span class="text-br-green fluid-xs leading-tightest font-semibold">
          {{ $t('shop.ourExperts') }}
        </span>
        <div
          class="content text-copy fluid-lg lg:fluid-base font-light lg:leading-9 lg:mt-4 mt-2 leading-6 text-white"
          v-html="testimonial.description"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';

export const ProductBlocksExpertFragment = gql`
  fragment ProductBlocksExpertFragment on productBlocks_expert_BlockType {
    typeHandle
  }
`;
export default {
  components: {
    FixedAspect,
    CldnImg,
  },
  props: {
    testimonials: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    testimonial() {
      return this.testimonials && this.testimonials[0] ? this.testimonials[0] : null;
    },
    imageSrc() {
      return this.testimonial?.image?.fullpath;
    },
  },
};
</script>

<style scoped>
.content >>> p:nth-child(2) {
  @apply mt-5 leading-normal;
}

.content >>> strong {
  @apply fluid-xs leading-tightest text-br-green-light;
}

.expert-picture >>> * {
  @apply h-full;
}
</style>
