import gql from 'graphql-tag'
import { LinkFragment } from '~/components/common/LinkField'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const AboutBlocksImageTextFragment = gql`
  fragment AboutBlocksImageTextFragment on aboutBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    alt
    invert
    heading
    body
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const ArticleBlocksImageTextFragment = gql`
  fragment ArticleBlocksImageTextFragment on articleBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    invert
    alt
    heading
    body
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const EducationBlocksImageTextFragment = gql`
  fragment EducationBlocksImageTextFragment on educationBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    invert
    alt
    heading
    body
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const MarketingBlocksImageTextFragment = gql`
  fragment MarketingBlocksImageTextFragment on marketingBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    invert
    alt
    heading
    body
    guestOnly
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const ProductBlocksImageTextFragment = gql`
  fragment ProductBlocksImageTextFragment on productBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    invert
    alt
    heading
    body
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
export const ShopBlocksImageTextFragment = gql`
  fragment ShopBlocksImageTextFragment on shopBlocks_imageText_BlockType {
    typeHandle
    image {
      ...CraftAssetFragment
    }
    invert
    alt
    heading
    body
    cta {
      ...LinkFragment
    }
  }
  ${LinkFragment}
  ${CraftAssetFragment}
`
