<template>
  <div v-if="asset" class="relative" :class="hfull ? 'h-full' : ''">
    <FixedAspect v-if="aspectRatio" :ratio="aspectRatio" @click.native="handleClick">
      <div v-if="isEmbedded" class="video-wrapper" :class="hfull ? 'h-full' : ''" v-html="code"></div>
      <CldnImg
        v-if="isImg"
        :src="src"
        :alt="alt ? alt : asset.title"
        :width="width"
        :ratio="aspectRatio"
        :sizes="sizes"
        :crop="crop"
      />
      <CldnVideo
        v-if="isVideo"
        :src="src"
        :width="width"
        :height="height"
        :crop="crop"
        :autoplay="autoplay"
        :muted="muted"
        :ratio="aspectRatio"
        :controls="controls"
        :is-thumbnail="modal"
      />
      <div
        v-if="isVideo && modal && !autoplay"
        class="modalIcon absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer text-red-700 transition-all delay-300 hover:text-blackly"
      >
        <YoutubeIcon v-if="isEmbedded" class="w-[62px] h-[45px]" />
        <PlayBtn v-else class="lg:w-20 lg:h-20 w-16 h-16" :class="[modal ? 'w-8 h-8' : 'w-12 h-12 lg:w-20 lg:h-20']" />
      </div>
    </FixedAspect>
    <div v-else class="absolute inset-0">
      <div v-if="isEmbedded" class="video-wrapper" :class="hfull ? 'h-full' : ''" v-html="code"></div>
      <CldnImg
        v-if="isImg"
        :class="[{ 'object-cover object-center h-full w-full': renderCovered }]"
        :src="src"
        :alt="alt ? alt : asset.title"
        :width="width"
        :ratio="aspectRatio"
        :sizes="sizes"
        :crop="crop"
      />
      <CldnVideo
        v-if="isVideo"
        :src="src"
        :width="width"
        :height="height"
        :crop="crop"
        :autoplay="autoplay"
        :muted="muted"
        :controls="controls"
        class="transform -translate-y-1/2 absolute top-1/2"
      />
      <div
        v-if="isVideo && modal && !autoplay"
        class="modalIcon absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center cursor-pointer text-red-700 transition-all delay-300 hover:text-blackly"
      >
        <YoutubeIcon v-if="isEmbedded" class="w-[62px] h-[45px]" />
        <PlayBtn v-else class="lg:w-20 lg:h-20 w-16 h-16" />
      </div>
    </div>
    <Modal
      v-if="modal"
      :showing="showingModal"
      :min-width="true"
      :fullscreen="fullscreen"
      @close="showingModal = false"
    >
      <div v-if="isEmbedded" class="video-wrapper" v-html="code"></div>
      <CldnVideo
        v-if="isVideo && showingModal"
        :src="src"
        :width="width"
        :height="height"
        :crop="crop"
        :autoplay="true"
        :muted="muted"
        :ratio="aspectRatio"
        :controls="controls"
      />
    </Modal>
  </div>
</template>

<script>
/**
 * generic asset renderer for images, videos or json-assets (youtube video)
 */
import gql from 'graphql-tag';
import CldnImg from '@/components/CldnImg';
import CldnVideo from '@/components/CldnVideo';
import FixedAspect from '@/components/common/FixedAspect';
import Modal from '@/components/common/Modal';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import PlayBtn from '@/components/common/PlayBtn';
export const CraftAssetFragment = gql`
  fragment CraftAssetFragment on assets_Asset {
    kind
    url
    width
    height
    title
  }
`;
export default {
  components: {
    CldnImg,
    CldnVideo,
    FixedAspect,
    Modal,
    YoutubeIcon,
    PlayBtn,
  },
  props: {
    alt: {
      type: String,
      default: null,
    },
    asset: {
      type: Object,
      default: () => null,
    },
    ratio: {
      type: Number,
      default: null,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    crop: {
      type: String,
      default: 'auto',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    sizes: {
      type: String,
      default: null,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hfull: {
      type: Boolean,
      default: false,
    },
    renderCovered: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      embedded: null,
      showingModal: false,
    };
  },
  computed: {
    aspectRatio() {
      return this.isEmbedded ? parseFloat(this.embedded?.aspectRatio) / 100 : this.ratio || null;
    },
    code() {
      let code = this.embedded?.code;
      if (code) {
        code = code.replace(/(width="\d+"|height="\d+")/g, '').replace(/<iframe /, '<iframe loading="lazy" ');
        return code;
      }
      return null;
    },
    isEmbedded() {
      return this.asset?.kind === 'json';
    },
    isImg() {
      return this.asset?.kind === 'image';
    },
    isVideo() {
      return this.asset?.kind === 'video';
    },
    src() {
      return this.isImg || this.isVideo ? this.asset?.url : null;
    },
  },
  async mounted() {
    if (this.isEmbedded) {
      try {
        this.embedded = await this.$axios.$get(this.asset?.url, {
          transformRequest: [
            (data, headers) => {
              delete headers.Authorization;
              delete headers.common.Authorization;
              return data;
            },
          ],
        });
      } catch (e) {
        console.error(`Failed Loading embedded asset!`, e);
      }
    }
  },
  methods: {
    handleClick() {
      if (this.modal) {
        this.showingModal = true;
      }
    },
  },
};
</script>

<style>
.controls {
  @apply !z-10;
}
@media screen and (min-width: 1024px) {
  .video-wrapper--maxHeight {
    max-height: calc(100vh - 80px);
  }
}
.video-wrapper iframe {
  @apply inset-0 absolute w-full h-full;
}
.btn-fullscreen {
  @apply absolute bottom-0 left-0 top-auto right-auto w-[10%] h-auto;
}
</style>
