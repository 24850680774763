import { buildEECProduct } from '@/utils/tracking'

export default function (ctx, inject) {
  const dlPush = {
    /**
     * push EEC tracking for adding to cart
     * @param {Object} variant -  shopify variant
     * @param {Object} product -  shopify product
     * @param {Number} quantity
     * @param {String} rating - optional start rating
     */
    eECaddToCart(variant, product, quantity, rating = null) {
      const products = [buildEECProduct(variant, product, quantity, rating)]
      const currencyCode = variant.price.currencyCode
      const data = {
        event: 'EECaddToCart',
        ecommerce: {
          currencyCode,
          add: {
            products
          }
        }
      }
      ctx.$_gtm.push(data)
    }
  }
  inject('dlPush', dlPush)
}
