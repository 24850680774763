import { CourseContentQuery } from '@/components/content/CourseContent/CourseContent.gql'

export async function fetchCourseContent(ctx, courseHandle) {
  const response = await ctx.$graphql(
    process.env.fegApiBase,
    CourseContentQuery,
    {
      handle: courseHandle,
      language: 'de'
    },
    {
      Authorization: `Basic ${process.env.fegApiAuth}`
    }
  )

  return response ? response.learnWorldsCourse : null
}
