<template>
  <div v-if="legalNav">
    <div class="flex flex-wrap justify-center lg:justify-start">
      <div v-for="(item, idx) in legalNav.linkList" :key="idx" class="my-1 mx-2 lg:m-0">
        <LinkField v-bind="item.linkField" class="fluid-xs leading-tight whitespace-nowrap" />
        <span v-if="idx < legalNav.linkList.length - 1" class="fluid-xs leading-tight mx-2 hidden lg:inline-block"
          >|</span
        >
      </div>
    </div>
    <div class="fluid-xs leading-tight mt-4 text-center md:text-left">
      © {{ year }} BLACKROLL.COM. ALL RIGHTS RESERVED
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import LinkField, { LinkFragment } from '@/components/common/LinkField'

export const LegalNavFragment = gql`
  fragment LegalNavFragment on legalNav_GlobalSet {
    linkList {
      ... on linkList_BlockType {
        linkField {
          ...LinkFragment
        }
      }
    }
  }
  ${LinkFragment}
`

export default {
  components: { LinkField },
  props: {
    legalNav: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>
