<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="{ product, tsProduct, description: product.descriptionHtml, accordions: product.accordion, ...block }"
        :add-on="addOn"
        :youtube-id="product.video"
        :exercise-page="exercisePage"
        :testimonials="product.testimonial"
        :handles="product.relatedProducts"
        :handle="product.bundleProduct"
        :features="product.keyFeatures"
        :agreement-category="block.agreementCategory ? block.agreementCategory : agreementCategory"
        :block-position="index"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ConversionAreaOne, {
  ProductBlocksConversionAreaOneFragment,
} from '@/components/shop/products/productBlocks/ConversionAreaOne/ConversionAreaOne';
import Accordion, { ProductBlocksAccordionFragment } from '@/components/shop/products/productBlocks/Accordion';
import AppCta, { ProductBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import VideoFeature from '@/components/media/VideoFeature/VideoFeature';
import { ProductBlocksVideoFeatureFragment } from '~/components/media/VideoFeature/VideoFeature.gql.js';
import ExerciseTeaser, {
  ProductBlocksExerciseTeaserFragment,
} from '@/components/shop/products/productBlocks/ExerciseTeaser/ExerciseTeaser';
import Expert, { ProductBlocksExpertFragment } from '@/components/shop/products/productBlocks/Expert/Expert';
import RelatedProducts, {
  ProductBlocksRelatedProductsFragment,
} from '@/components/shop/products/productBlocks/RelatedProducts/RelatedProducts';
import ProductInformation, {
  ProductBlocksProductInformationFragment,
} from '@/components/shop/products/productBlocks/ProductInformation/ProductInformation';
import ProductReviews, {
  ProductBlocksProductReviewsFragment,
} from '@/components/shop/products/productBlocks/ProductReviews/ProductReviews';
import AlsoBought, {
  ProductBlocksAlsoBoughtFragment,
} from '@/components/shop/products/productBlocks/AlsoBought/AlsoBought';
import ImageText from '@/components/media/MediaText/MediaText';
import { ProductBlocksImageTextFragment } from '@/components/media/MediaText/MediaText.gql.js';
import SplitContentSlider from '@/components/media/SplitContentSlider/SplitContentSlider';
import { ProductBlocksSplitContentSliderFragment } from '@/components/media/SplitContentSlider/SplitContentSlider.gql.js';
import AccordionBlock, {
  ProductBlocksAccordionBlockFragment,
} from '@/components/shop/products/productBlocks/AccordionBlock';
import GenericIframe from '@/components/content/GenericIframe/GenericIframe';
import { ProductBlocksGenericIframeFragment } from '@/components/content/GenericIframe/GenericIframe.gql.js';
import TeaserWithCta from '@/components/ctas/TeaserWithCta/TeaserWithCta';
import { ProductBlocksTeaserWithCtaFragment } from '~/components/ctas/TeaserWithCta/TeaserWithCta.gql.js';
import ProductBlocksNewsletterSignupFormFragment from '@/components/forms/NewsletterSignupForm/ProductBlocksNewsletterSignupFormFragment.gql';
import NewsletterSignupForm from '@/components/forms/NewsletterSignupForm';
import CenteredContent from '@/components/content/CenteredContent/CenteredContent';
import { ProductBlocksCenteredContentFragment } from '@/components/content/CenteredContent/CenteredContent.gql';
import VideoWithDescription from '@/components/media/VideoWithDescription/VideoWithDescription';
import { ProductBlocksVideoWithDescriptionFragment } from '@/components/media/VideoWithDescription/VideoWithDescription.gql';
import { ProductBlocksCourseConversionBannerFragment } from '~/components/ctas/CourseConversionBanner/CourseConversionBanner.gql.js';
import CourseConversionBanner from '~/components/ctas/CourseConversionBanner/CourseConversionBanner';
import { ProductBlocksFeatureListFragment } from '~/components/content/FeatureList/FeatureList.gql';
import FeatureList from '~/components/content/FeatureList/FeatureList';
import InsuranceReimbursement from '~/components/generic/InsuranceReimbursement/InsuranceReimbursement';
import { ProductBlocksInsuranceReimbursementFragment } from '~/components/generic/InsuranceReimbursement/InsuranceReimbursement.gql';
import { ProductBlocksCourseContentFragment } from '~/components/content/CourseContent/CourseContent.gql';
import CourseContent from '~/components/content/CourseContent/CourseContent';
import CrossSell, { ProductBlocksCrossSellFragment } from '~/components/ctas/CrossSell';
import Upsell, { ProductBlocksUpsellFragment } from '~/components/ctas/Upsell';
import TestimonialWithVariedBackground, {
  ProductBlocksTestimonialWithVariedBackgroundFragment,
} from '@/components/common/blocks/TestimonialWithVariedBackground';
import ImageGallery, { ProductBlocksImageGalleryFragment } from '@/components/common/blocks/ImageGallery/index';
import FeaturesWithIcons, {
  ProductBlocksFeaturesWithIconsFragment,
} from '@/components/common/blocks/FeaturesWithIcons';

export const ProductBlocksMatrixFragment = gql`
  fragment ProductBlocksMatrixFragment on productBlocks_MatrixField {
    ...ProductBlocksConversionAreaOneFragment
    ...ProductBlocksAccordionFragment
    ...ProductBlocksAppCtaFragment
    ...ProductBlocksVideoFeatureFragment
    ...ProductBlocksExerciseTeaserFragment
    ...ProductBlocksExpertFragment
    ...ProductBlocksRelatedProductsFragment
    ...ProductBlocksProductInformationFragment
    ...ProductBlocksProductReviewsFragment
    ...ProductBlocksAlsoBoughtFragment
    ...ProductBlocksImageTextFragment
    ...ProductBlocksSplitContentSliderFragment
    ...ProductBlocksAccordionBlockFragment
    ...ProductBlocksGenericIframeFragment
    ...ProductBlocksTeaserWithCtaFragment
    ...ProductBlocksNewsletterSignupFormFragment
    ...ProductBlocksCenteredContentFragment
    ...ProductBlocksVideoWithDescriptionFragment
    ...ProductBlocksCourseConversionBannerFragment
    ...ProductBlocksFeatureListFragment
    ...ProductBlocksInsuranceReimbursementFragment
    ...ProductBlocksCourseContentFragment
    ...ProductBlocksUpsellFragment
    ...ProductBlocksCrossSellFragment
    ...ProductBlocksTestimonialWithVariedBackgroundFragment
    ...ProductBlocksImageGalleryFragment
    ...ProductBlocksFeaturesWithIconsFragment
  }
  ${ProductBlocksConversionAreaOneFragment}
  ${ProductBlocksAccordionFragment}
  ${ProductBlocksAppCtaFragment}
  ${ProductBlocksVideoFeatureFragment}
  ${ProductBlocksExerciseTeaserFragment}
  ${ProductBlocksExpertFragment}
  ${ProductBlocksRelatedProductsFragment}
  ${ProductBlocksProductInformationFragment}
  ${ProductBlocksProductReviewsFragment}
  ${ProductBlocksAlsoBoughtFragment}
  ${ProductBlocksImageTextFragment}
  ${ProductBlocksSplitContentSliderFragment}
  ${ProductBlocksAccordionBlockFragment}
  ${ProductBlocksGenericIframeFragment}
  ${ProductBlocksTeaserWithCtaFragment}
  ${ProductBlocksNewsletterSignupFormFragment}
  ${ProductBlocksCenteredContentFragment}
  ${ProductBlocksCourseConversionBannerFragment}
  ${ProductBlocksVideoWithDescriptionFragment}
  ${ProductBlocksFeatureListFragment}
  ${ProductBlocksInsuranceReimbursementFragment}
  ${ProductBlocksCourseContentFragment}
  ${ProductBlocksUpsellFragment}
  ${ProductBlocksCrossSellFragment}
  ${ProductBlocksTestimonialWithVariedBackgroundFragment}
  ${ProductBlocksImageGalleryFragment}
  ${ProductBlocksFeaturesWithIconsFragment}
`;

export default {
  name: 'ProductBlocks',
  components: {
    GenericIframe,
    AccordionBlock,
    ConversionAreaOne,
    Accordion,
    AppCta,
    VideoFeature,
    ExerciseTeaser,
    Expert,
    RelatedProducts,
    ProductInformation,
    ProductReviews,
    AlsoBought,
    ImageText,
    SplitContentSlider,
    CenteredContent,
    TeaserWithCta,
    NewsletterSignupForm,
    FeatureList,
    CourseConversionBanner,
    VideoWithDescription,
    InsuranceReimbursement,
    CourseContent,
    CrossSell,
    Upsell,
    TestimonialWithVariedBackground,
    ImageGallery,
    FeaturesWithIcons,
  },
  mixins: [guestComponent],
  props: {
    entry: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      default: () => null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
    addOn: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    exercisePage() {
      return this.entry?.exercisePage;
    },
    agreementCategory() {
      return this.entry?.agreementCategory;
    },
    blocks() {
      return this.entry && this.entry.productBlocks ? this.entry.productBlocks : [];
    },
  },
  methods: {
    tsIsSet() {
      const { locale, locales } = this.$i18n;
      const tsid = locales.find((l) => l.code === locale)?.tsid;
      return !!tsid;
    },
  },
};
</script>
