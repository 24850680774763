<template>
  <GenericIframe v-bind="$props" />
</template>

<script>
import gql from 'graphql-tag'
import GenericIframe from '~/components/content/GenericIframe/GenericIframe'

export const MarketingBlocksWallsIoFeedFragment = gql`
  fragment MarketingBlocksWallsIoFeedFragment on marketingBlocks_wallsIoFeed_BlockType {
    typeHandle
    iframeCode
  }
`
export default {
  components: { GenericIframe },
  props: {
    iframeCode: {
      type: String,
      default: null
    }
  }
}
</script>
