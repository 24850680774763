<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="{ ...block, socialMediaAccounts, blockPosition: index }"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import gql from 'graphql-tag';
import AppCta, { MarketingBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import FullWidthHeadingCta from '@/components/media/FullWidthHeadingCta/FullWidthHeadingCta';
import { MarketingBlocksFullWidthHeadingCtaFragment } from '@/components/media/FullWidthHeadingCta/FullWidthHeadingCta.gql';
import SmallTopicFeatureWithBars, {
  MarketingBlocksSmallTopicFeatureBarsFragment,
} from '@/components/common/blocks/SmallTopicFeatureBars';
import TeaserWithMultipleCtas, {
  MarketingBlocksTeaserWithMultipleCtasFragment,
} from '@/components/marketing/marketingBlocks/TeaserWithMultipleCtas';
import FullWidthTeaserBox from '@/components/media/FullWidthTeaserBox/FullWidthTeaserBox';
import { MarketingBlocksFullWidthTeaserBoxFragment } from '@/components/media/FullWidthTeaserBox/FullWidthTeaserBox.gql';
import ExerciseRoutineCardsSliderWithText, {
  MarketingBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import CenteredContent from '@/components/content/CenteredContent/CenteredContent';
import { MarketingBlocksCenteredContentFragment } from '@/components/content/CenteredContent/CenteredContent.gql';
import TestimonialWithVariedBackground, {
  MarketingBlocksTestimonialWithVariedBackgroundFragment,
} from '@/components/common/blocks/TestimonialWithVariedBackground';
import MovingFullWidthImages, {
  MarketingBlocksMovingFullWidthImagesFragment,
} from '@/components/common/blocks/MovingFullWidthImages';
import CollectionsSlider, {
  MarketingBlocksCollectionsSliderFragment,
} from '@/components/common/blocks/CollectionsSlider';
import ProductSlider, { MarketingBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import RoutineCardsSlider, {
  MarketingBlocksRoutineCardsSliderFragment,
} from '@/components/common/blocks/RoutineCardsSlider';
import SmallTopicFeatureBox, {
  MarketingBlocksSmallTopicFeatureBoxFragment,
} from '@/components/common/blocks/SmallTopicFeatureBox';
import ArticleRowSlide, { MarketingBlocksArticleRowSlideFragment } from '@/components/common/blocks/ArticleRowSlide';
import SmallStoryFeaturesWithBars, {
  MarketingPageBlocksSmallStoryFeaturesWithBars,
} from '@/components/marketing/marketingBlocks/SmallStoryFeaturesWithBars';
import Newsletter, { MarketingBlocksNewsletterFragment } from '@/components/marketing/marketingBlocks/Newsletter';
import FiftyFiftyArticleTeaser, {
  MarketingBlocksFiftyFiftyArticleTeaserFragment,
} from '@/components/common/blocks/FiftyFiftyArticleTeaser';
import LargeStoryFeatureWithBars, {
  MarketingBlocksLargeStoryFeatureWithBarsFragment,
} from '@/components/common/blocks/LargeStoryFeatureWithBars';
import ImageText from '@/components/media/MediaText/MediaText';
import { MarketingBlocksImageTextFragment } from '@/components/media/MediaText/MediaText.gql';
import CopyText, { MarketingBlocksCopyTextFragment } from '@/components/marketing/marketingBlocks/CopyText';
import SocialFeature, { MarketingBlocksSocialFeatureFragment } from '@/components/common/blocks/SocialFeature';
import FormLink, { MarketingBlocksFormLinkFragment } from '@/components/common/blocks/FormLink';
import AsSeenIn, { MarketingBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import SplitContentSlider from '@/components/media/SplitContentSlider/SplitContentSlider';
import { MarketingBlocksSplitContentSliderFragment } from '@/components/media/SplitContentSlider/SplitContentSlider.gql';
import HeroProduct, { MarketingBlocksHeroProductFragment } from '@/components/common/blocks/HeroProduct';
import WallsIoFeed, {
  MarketingBlocksWallsIoFeedFragment,
} from '@/components/marketing/marketingBlocks/WallsIoFeed/WallsIoFeed';
import FormInModal, {
  MarketingBlocksFormInModalFragment,
} from '@/components/marketing/marketingBlocks/FormInModal/FormInModal';
import FixedBoxImages, {
  MarketingBlocksFixedBoxImagesFragment,
} from '@/components/marketing/marketingBlocks/FixedBoxImages/FixedBoxImages';
import ImageOrVideo from '@/components/media/ImageOrVideo/ImageOrVideo';
import { MarketingBlocksImageOrVideoFragment } from '@/components/media/ImageOrVideo/ImageOrVideo.gql';
import FeaturesRotation, {
  MarketingPagesFeaturesRotationFragment,
} from '@/components/marketing/marketingBlocks/FeaturesRotation';
import Accordion, { MarketingBlocksAccordionFragment } from '@/components/marketing/marketingBlocks/Accordion';
import InContentCta, { MarketingBlocksInContentCtaFragment } from '@/components/common/blocks/InContentCta';
import InContentCtaBlocks, {
  MarketingBlocksInContentCtaBlocksFragment,
} from '@/components/common/blocks/InContentCtaBlocks';
import FullWidthAutoplayVideo from '@/components/media/FullWidthAutoplayVideo/FullWidthAutoplayVideo';
import { MarketingBlocksFullWidthAutoplayVideoFragment } from '@/components/media/FullWidthAutoplayVideo/FullWidthAutoPlayVideo.gql';
import ParallaxImages from '@/components/media/ParallaxImage/ParallaxImage';
import { MarketingBlocksParallaxImagesFragment } from '@/components/media/ParallaxImage/ParallaxImage.gql';
import GenericIframe from '@/components/content/GenericIframe/GenericIframe';
import { MarketingBlocksGenericIframeFragment } from '@/components/content/GenericIframe/GenericIframe.gql';
import MarketingBlocksNewsletterSignupFormFragment from '@/components/forms/NewsletterSignupForm/MarketingBlocksNewsletterSignupFormFragment.gql';
import NewsletterSignupForm from '@/components/forms/NewsletterSignupForm';
import FeatureList from '~/components/content/FeatureList/FeatureList';
import { MarketingBlocksFeatureListFragment } from '~/components/content/FeatureList/FeatureList.gql';
import ConversionAreaOne from '~/components/shop/products/productBlocks/ConversionAreaOne/CommonConversionArea';
import { MarketingBlocksConversionAreaOneFragment } from '~/components/shop/products/productBlocks/ConversionAreaOne/ConversionAreaOne.gql';
import { MarketingBlocksProductReviewsFragment } from '~/components/shop/products/productBlocks/ProductReviews/ProductReviews.gql';
import ProductReviews from '~/components/shop/products/productBlocks/ProductReviews/CommonProductReviews';
import CourseConversionBanner from '~/components/ctas/CourseConversionBanner/CourseConversionBanner';
import { MarketingBlocksCourseConversionBannerFragment } from '~/components/ctas/CourseConversionBanner/CourseConversionBanner.gql';
import CrossSell, { MarketingBlocksCrossSellFragment } from '~/components/ctas/CrossSell';
import Upsell, { MarketingBlocksUpsellFragment } from '~/components/ctas/Upsell';

import guestComponent from '@/mixins/guestComponent';
import InsuranceReimbursement from '~/components/generic/InsuranceReimbursement/InsuranceReimbursement';
import { MarketingBlocksInsuranceReimbursementFragment } from '~/components/generic/InsuranceReimbursement/InsuranceReimbursement.gql';
import { MarketingBlocksCourseContentFragment } from '~/components/content/CourseContent/CourseContent.gql';
import CourseContent from '~/components/content/CourseContent/CourseContent';

export const MarketingBlocksMatrixFragment = gql`
  fragment MarketingBlocksMatrixFragment on marketingBlocks_MatrixField {
    __typename
    ...MarketingBlocksAppCtaFragment
    ...MarketingBlocksFullWidthHeadingCtaFragment
    ...MarketingBlocksSmallTopicFeatureBarsFragment
    ...MarketingBlocksTeaserWithMultipleCtasFragment
    ...MarketingBlocksFullWidthTeaserBoxFragment
    ...MarketingBlocksRoutineCardsSliderWithTextFragment
    ...MarketingBlocksCenteredContentFragment
    ...MarketingBlocksTestimonialWithVariedBackgroundFragment
    ...MarketingBlocksMovingFullWidthImagesFragment
    ...MarketingBlocksCollectionsSliderFragment
    ...MarketingBlocksProductSliderFragment
    ...MarketingBlocksRoutineCardsSliderFragment
    ...MarketingBlocksSmallTopicFeatureBoxFragment
    ...MarketingBlocksArticleRowSlideFragment
    ...MarketingPageBlocksSmallStoryFeaturesWithBars
    ...MarketingBlocksNewsletterFragment
    ...MarketingBlocksFiftyFiftyArticleTeaserFragment
    ...MarketingBlocksLargeStoryFeatureWithBarsFragment
    ...MarketingBlocksImageTextFragment
    ...MarketingBlocksCopyTextFragment
    ...MarketingBlocksSocialFeatureFragment
    ...MarketingBlocksFormLinkFragment
    ...MarketingBlocksAsSeenInFragment
    ...MarketingBlocksSplitContentSliderFragment
    ...MarketingBlocksHeroProductFragment
    ...MarketingBlocksWallsIoFeedFragment
    ...MarketingBlocksFormInModalFragment
    ...MarketingBlocksFixedBoxImagesFragment
    ...MarketingBlocksImageOrVideoFragment
    ...MarketingPagesFeaturesRotationFragment
    ...MarketingBlocksAccordionFragment
    ...MarketingBlocksInContentCtaFragment
    ...MarketingBlocksInContentCtaBlocksFragment
    ...MarketingBlocksFullWidthAutoplayVideoFragment
    ...MarketingBlocksParallaxImagesFragment
    ...MarketingBlocksGenericIframeFragment
    ...MarketingBlocksNewsletterSignupFormFragment
    ...MarketingBlocksFeatureListFragment
    ...MarketingBlocksConversionAreaOneFragment
    ...MarketingBlocksProductReviewsFragment
    ...MarketingBlocksCourseConversionBannerFragment
    ...MarketingBlocksInsuranceReimbursementFragment
    ...MarketingBlocksCourseContentFragment
    ...MarketingBlocksCrossSellFragment
    ...MarketingBlocksUpsellFragment
  }
  ${MarketingBlocksAppCtaFragment}
  ${MarketingBlocksFullWidthHeadingCtaFragment}
  ${MarketingBlocksSmallTopicFeatureBarsFragment}
  ${MarketingBlocksTeaserWithMultipleCtasFragment}
  ${MarketingBlocksFullWidthTeaserBoxFragment}
  ${MarketingBlocksRoutineCardsSliderWithTextFragment}
  ${MarketingBlocksCenteredContentFragment}
  ${MarketingBlocksTestimonialWithVariedBackgroundFragment}
  ${MarketingBlocksMovingFullWidthImagesFragment}
  ${MarketingBlocksCollectionsSliderFragment}
  ${MarketingBlocksProductSliderFragment}
  ${MarketingBlocksRoutineCardsSliderFragment}
  ${MarketingBlocksSmallTopicFeatureBoxFragment}
  ${MarketingBlocksArticleRowSlideFragment}
  ${MarketingPageBlocksSmallStoryFeaturesWithBars}
  ${MarketingBlocksNewsletterFragment}
  ${MarketingBlocksFiftyFiftyArticleTeaserFragment}
  ${MarketingBlocksLargeStoryFeatureWithBarsFragment}
  ${MarketingBlocksImageTextFragment}
  ${MarketingBlocksCopyTextFragment}
  ${MarketingBlocksSocialFeatureFragment}
  ${MarketingBlocksFormLinkFragment}
  ${MarketingBlocksAsSeenInFragment}
  ${MarketingBlocksSplitContentSliderFragment}
  ${MarketingBlocksHeroProductFragment}
  ${MarketingBlocksWallsIoFeedFragment}
  ${MarketingBlocksFormInModalFragment}
  ${MarketingBlocksFixedBoxImagesFragment}
  ${MarketingBlocksImageOrVideoFragment}
  ${MarketingPagesFeaturesRotationFragment}
  ${MarketingBlocksAccordionFragment}
  ${MarketingBlocksInContentCtaFragment}
  ${MarketingBlocksInContentCtaBlocksFragment}
  ${MarketingBlocksFullWidthAutoplayVideoFragment}
  ${MarketingBlocksParallaxImagesFragment}
  ${MarketingBlocksGenericIframeFragment}
  ${MarketingBlocksNewsletterSignupFormFragment}
  ${MarketingBlocksFeatureListFragment}
  ${MarketingBlocksConversionAreaOneFragment}
  ${MarketingBlocksProductReviewsFragment}
  ${MarketingBlocksCourseConversionBannerFragment}
  ${MarketingBlocksInsuranceReimbursementFragment}
  ${MarketingBlocksCourseContentFragment}
  ${MarketingBlocksCrossSellFragment}
  ${MarketingBlocksUpsellFragment}
`;
export default {
  name: 'MarketingBlocks',
  components: {
    FeaturesRotation,
    Accordion,
    InContentCtaBlocks,
    InContentCta,
    FullWidthAutoplayVideo,
    ParallaxImages,
    GenericIframe,
    ImageOrVideo,
    WallsIoFeed,
    FormInModal,
    FixedBoxImages,
    SplitContentSlider,
    FormLink,
    AsSeenIn,
    SocialFeature,
    SmallTopicFeatureBox,
    RoutineCardsSlider,
    CollectionsSlider,
    ProductSlider,
    MovingFullWidthImages,
    CenteredContent,
    FullWidthHeadingCta,
    AppCta,
    SmallTopicFeatureWithBars,
    TeaserWithMultipleCtas,
    FullWidthTeaserBox,
    ExerciseRoutineCardsSliderWithText,
    TestimonialWithVariedBackground,
    ArticleRowSlide,
    SmallStoryFeaturesWithBars,
    Newsletter,
    FiftyFiftyArticleTeaser,
    LargeStoryFeatureWithBars,
    ImageText,
    CopyText,
    HeroProduct,
    NewsletterSignupForm,
    FeatureList,
    ConversionAreaOne,
    ProductReviews,
    CourseConversionBanner,
    InsuranceReimbursement,
    CourseContent,
    CrossSell,
    Upsell,
  },
  mixins: [guestComponent],
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    socialMediaAccounts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
