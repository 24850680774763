<template>
  <!--  <div class="bg-br-green-light" />-->
  <div v-show="show" class="py-l" :class="{ 'bg-br-green-light': background }">
    <div class="container max-w-3xl">
      <div class="mx-auto text-center">
        <div class="fluid-3xl leading-tightest font-semibold" v-html="heading"></div>
      </div>
      <div class="mx-auto">
        <NewsletterSignupForm class="md:mt-12 mt-6" />
      </div>
    </div>
  </div>
</template>

<script>
import NewsletterSignupForm from '@/components/common/NewsletterSignupForm';
import gql from 'graphql-tag';

export const NewsletterSignupFragment = gql`
  fragment NewsletterSignupFragment on newsletterSignup_GlobalSet {
    handle
    simpleHeading
  }
`;

export default {
  components: {
    NewsletterSignupForm,
  },
  props: {
    /**
     * cheange behaviour to act as the global fallback newsletter in footer
     */
    globalFallback: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    background: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show() {
      if (!this.globalFallback) return true;
      return this.$store.getters['newsletterBlocks/showGlobalFallback'];
    },
  },
  mounted() {
    this.incrementInContentBlocks();
  },
  methods: {
    /**
     * increment the number of in-content newsletter blocks if we are not the footer-fallback
     */
    incrementInContentBlocks() {
      if (!this.globalFallback) {
        this.$store.commit('newsletterBlocks/increment');
      }
    },
  },
};
</script>
