<template>
  <div v-if="hasHandles" class="related-products bg-putty relative py-20">
    <ProductCardSlider :heading="$t('product.relatedProducts')" :products="products" class="w-full" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ProductCardSlider from '@/components/common/ProductCardSlider'

export const ProductBlocksRelatedProductsFragment = gql`
  fragment ProductBlocksRelatedProductsFragment on productBlocks_relatedProducts_BlockType {
    typeHandle
  }
`

export default {
  name: 'RelatedProducts',
  components: { ProductCardSlider },
  props: {
    handles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      products: []
    }
  },
  async fetch() {
    if (this.hasHandles) {
      await this.fetchProducts(this.handles)
    }
  },
  computed: {
    hasHandles() {
      return this.handles?.length > 0
    }
  },
  methods: {
    fetchProducts(handles) {
      return this.$shopifyProductsRequest(handles).then((products) => {
        this.products = products.filter((product) => product?.availableForSale)
      })
    }
  }
}
</script>
