<template>
  <div v-if="nav && nav.blocks.length > 0" class="root gap-x-8 gap-y-8 grid">
    <div v-for="(column, idxA) in columns" :key="idxA" class="gap-y-8 md:text-left grid text-center">
      <div v-for="(block, idxB) in column.blocks" :key="idxB">
        <div v-for="(item, idxC) in block.links" :key="idxC">
          <LinkField
            v-bind="item.link"
            :class="{ 'fluid-xs leading-snug uppercase font-medium': idxC === 0 }"
            class="hover:opacity-60 fluid-xs leading-tightest inline-block mb-2 transition-opacity"
            no-tracking
            @click.native="handleNavClick(idxC)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import LinkField, { LinkFragment } from '@/components/common/LinkField';

export const FooterNavFragment = gql`
  fragment FooterNavFragment on footerNav_GlobalSet {
    blocks: footerNavBlocks {
      ... on footerNavBlocks_linkSection_BlockType {
        startNewColumn
        links: footerNavLinkSectionLinks {
          ... on footerNavLinkSectionLinks_BlockType {
            link: linkField {
              ...LinkFragment
            }
          }
        }
      }
    }
  }
  ${LinkFragment}
`;

export default {
  components: { LinkField },
  props: {
    nav: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /**
     * split blocks into colums
     * @returns {array} of arrays of blocks
     */
    columns() {
      const columns = [];
      let currentColumnIndex = 0;
      this.nav.blocks.forEach((block, i) => {
        if (i === 0) {
          columns.push({
            blocks: [block],
          });
        } else if (block.startNewColumn || block.typeHandle === 'imageFeature') {
          columns.push({
            blocks: [block],
          });
          currentColumnIndex++;
        } else {
          columns[currentColumnIndex].blocks.push(block);
        }
      });
      return columns;
    },
  },
  methods: {
    handleNavClick(index) {
      const eventLabel = index === 0 ? 'first level navigation' : 'second level navigation';

      const data = {
        event: 'gaEvent',
        event_name: 'navigation',
        navigation: {
          navigation_area: 'Footer',
          navigation_item: eventLabel,
          event_category: 'Navigation',
          event_action: 'Footer',
          event_label: eventLabel,
        },
      };
      this.$_gtm.push(data);
      this.$store.dispatch('mainNav/setOpenNavTopIndex');
    },
  },
};
</script>

<style scoped>
.root {
  grid-template-columns: repeat(1, 1fr);
}

@screen md {
  .root {
    grid-template-columns: repeat(2, 1fr);
  }
}

@screen lg {
  .root {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
