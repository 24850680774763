<template>
  <CommonAccordion :heading="heading" :body="body" />
</template>

<script>
import gql from 'graphql-tag'
import CommonAccordion from '@/components/common/Accordion'

export const MarketingBlocksAccordionFragment = gql`
  fragment MarketingBlocksAccordionFragment on marketingBlocks_accordion_BlockType {
    typeHandle
    heading
    body
  }
`

export default {
  components: {
    CommonAccordion
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    }
  }
}
</script>
