<template>
  <div v-track:view="gtmData()" class="relative my-20">
    <div class="container flex justify-center mt-10 mb-5 text-center">
      <h2 class="fluid-3xl leading-none text-copy md:w-2/3 block w-full text-center" v-html="heading"></h2>
    </div>
    <div class="overflow-x-hidden">
      <div v-if="!isLoading" ref="slides" class="slides-container gap-x-12 grid grid-cols-5 my-20">
        <ParallaxBox v-for="(image, index) in images" :key="index" :factor="index % 2 === 0 ? -50 : 50">
          <a
            v-track:click="gtmData({ name: image.socialNetwork, type: 'post' }, index)"
            :href="image.postUrl"
            class="relative block w-full h-full"
            target="_blank"
          >
            <FixedAspect :ratio="1">
              <img
                :src="image.src"
                :alt="image.altText"
                sizes="20vw"
                class="hide-not-redrawn-pixels absolute z-10 block w-full"
                data-test-id="social-image"
              />
            </FixedAspect>
            <div
              v-if="image.socialNetwork === 'Instagram'"
              class="social-network-icon sm:w-12 sm:h-12 bg-blackly absolute top-0 right-0 flex items-center justify-center w-8 h-8 rounded-full"
            >
              <InstagramIcon class="sm:w-6 sm:h-6 block w-4 h-4 text-white" />
            </div>
          </a>
        </ParallaxBox>
      </div>
      <div v-if="isLoading" class="slides-container gap-x-12 mt-l grid grid-cols-5 mb-20">
        <FixedAspect v-for="(item, index) in placeholders" :key="index" :ratio="1">
          <Placeholder class="w-full h-full" />
        </FixedAspect>
      </div>
    </div>
    <div v-if="socialMediaAccountsList" class="container text-center">
      <span class="fluid-sm tracking-wider font-medium" data-test-id="social-links-title">{{
        $t('followBlackroll')
      }}</span>
      <div class="flex flex-row justify-center w-full">
        <a
          v-for="(socialMediaAccount, index) in socialMediaAccounts"
          :key="index"
          v-track:click="gtmData({ name: socialMediaAccount.network, type: 'follow' }, index)"
          :href="socialMediaAccount.accountUrl"
          class="mx-8 my-6"
          target="_blank"
          data-test-id="social-link"
        >
          <FacebookIcon v-if="socialMediaAccount.network === 'facebook'" class="h-6" data-test-id="facebook" />
          <InstagramIcon v-if="socialMediaAccount.network === 'instagram'" class="h-6" data-test-id="instagram" />
          <YoutubeIcon v-if="socialMediaAccount.network === 'youtube'" class="h-6" data-test-id="youtube" />
          <TwitterIcon v-if="socialMediaAccount.network === 'twitter'" class="h-6" data-test-id="twitter" />
          <LinkedinIcon v-if="socialMediaAccount.network === 'linkedin'" class="h-6" data-test-id="linkedin" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import FixedAspect from '@/components/common/FixedAspect';
import ParallaxBox from '@/components/common/ParallaxBox';
import Placeholder from '@/components/common/Placeholder';
import FacebookIcon from '@/components/icons/FacebookIcon';
import InstagramIcon from '@/components/icons/InstagramIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import TwitterIcon from '@/components/icons/TwitterIcon';
import LinkedinIcon from '@/components/icons/LinkedinIcon';

export const StoriesPageBlocksSocialFeatureFragment = gql`
  fragment StoriesPageBlocksSocialFeatureFragment on storiesPageBlocks_socialFeature_BlockType {
    typeHandle
    heading
  }
`;

export const MarketingBlocksSocialFeatureFragment = gql`
  fragment MarketingBlocksSocialFeatureFragment on marketingBlocks_socialFeature_BlockType {
    typeHandle
    heading
  }
`;

export default {
  name: 'SocialFeature',
  components: {
    LinkedinIcon,
    TwitterIcon,
    YoutubeIcon,
    InstagramIcon,
    FacebookIcon,
    FixedAspect,
    ParallaxBox,
    Placeholder,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    socialMediaAccounts: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      posts: [],
      placeholders: new Array(5),
    };
  },

  computed: {
    instagramAccount() {
      const [accountLink] = this.socialMediaAccounts?.filter((account) => account.network === 'instagram');
      return accountLink?.accountUrl?.replace('https://www.instagram.com/', '');
    },
    apiUrl() {
      if (this.instagramAccount) {
        return `https://www.juicer.io/api/feeds/blackroll?per=5&page=1&filter=${this.instagramAccount}`;
      }
      return 'https://www.juicer.io/api/feeds/blackroll-socialwall?per=5&page=1&filter=Instagram';
    },
    isLoading() {
      return !this.images.length;
    },
    images() {
      return this.posts.map((img) => ({
        height: img.height,
        width: img.width,
        src: img.image,
        altText: img.feed,
        postUrl: img.full_url,
        socialNetwork: img.source.source,
      }));
    },
    socialMediaAccountsList() {
      return !!this.socialMediaAccounts?.length;
    },
  },

  async mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleResize);

    await this.fetchPosts();
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    fetchPosts() {
      this.$axios
        .$get(this.apiUrl)
        .then((data) => {
          this.posts = data.posts.items;
        })
        .catch((error) => this.$sentry.captureException(error));
    },
    getSlidesOffsetFromViewportCenter(rect) {
      const math = (rect.top + rect.height / 2 - window.innerHeight / 2) / window.innerHeight;
      if (math < -1) {
        return -1;
      } else if (math > 1) {
        return 1;
      } else {
        return math;
      }
    },
    handleAnimation() {
      const refSlides = this.$refs?.slides;
      if (refSlides) {
        const rect = refSlides.getBoundingClientRect();

        const math = this.getSlidesOffsetFromViewportCenter(rect);
        if (math > 1 || math < -1) {
          return;
        }

        window.requestAnimationFrame(() => {
          refSlides.style.transform = `translateX(calc(${math} * ${(rect.width - window.innerWidth) / 2}px))`;
        });
      }
    },
    handleResize() {
      this.handleAnimation();
    },
    handleScroll(event) {
      this.handleAnimation();
    },

    gtmData(payload = {}, index = 0) {
      let title = this.heading;

      if (payload.name && payload.type) {
        title = [payload.name, payload.type].join('-');
      }

      return {
        promotion_id: this.heading?.toLowerCase().replace(/ /g, '-'),
        promotion_name: [this.$route?.name, title].join(' / '),
        creative_name: 'socialFeature',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>

<style>
.slides-container {
  margin-left: -50vw;
  margin-right: -50vw;
}
@screen md {
  .slides-container {
    margin-left: -30vw;
    margin-right: -30vw;
  }
}
@screen lg {
  .slides-container {
    margin-left: -10vw;
    margin-right: -10vw;
  }
}
.social-network-icon {
  margin-top: 0.8rem;
  margin-right: 0.8rem;
}
/* fix for webkit repainting moving image issue */
.hide-not-redrawn-pixels {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}
</style>
