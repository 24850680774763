import gql from 'graphql-tag'
import { CraftAssetFragment } from '~/components/common/CraftAsset'

export const MarketingBlocksParallaxImagesFragment = gql`
  fragment MarketingBlocksParallaxImagesFragment on marketingBlocks_parallaxImages_BlockType {
    typeHandle
    heading
    firstImageVideo {
      ...CraftAssetFragment
    }
    secondImageVideo {
      ...CraftAssetFragment
    }
    thirdImageVideo {
      ...CraftAssetFragment
    }
    overlayHeading
    overlayText
  }
  ${CraftAssetFragment}
`
export const AboutBlocksParallaxImagesFragment = gql`
  fragment AboutBlocksParallaxImagesFragment on aboutBlocks_parallaxImages_BlockType {
    typeHandle
    heading
    firstImageVideo {
      ...CraftAssetFragment
    }
    secondImageVideo {
      ...CraftAssetFragment
    }
    thirdImageVideo {
      ...CraftAssetFragment
    }
    overlayHeading
    overlayText
  }
  ${CraftAssetFragment}
`
