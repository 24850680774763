<template>
  <AisInstantSearch v-if="client" :search-client="client" :index-name="indexName" :stalled-search-delay="300">
    <AisConfigure :hits-per-page.camel="40" v-bind="searchParameters" />
    <div
      v-show="showBackdrop && isOpen && searchQuery.length > 0"
      class="bg-blackly bg-opacity-75 absolute inset-x-0 top-full bottom-0 backdrop-blur-sm h-screen"
      @click="close"
    ></div>
    <div class="flex flex-col w-full">
      <div class="bg-white w-full py-4 z-10 shadow-lg">
        <AisSearchBox>
          <div slot-scope="{ currentRefinement, refine }" class="relative container flex lg:block">
            <form class="relative mx-auto max-w-2xl grow" @submit.prevent="handleSubmit()">
              <input
                ref="searchInput"
                type="search"
                :value="searchQuery"
                :placeholder="$t('search.inputPlaceholder')"
                class="fluid-base focus:text-blackly search-input border-2 focus:bg-white w-full bg-putty rounded-full pl-12 h-12 border-transparent focus:border-br-green pr-4 leading-tightest font-medium flex items-center"
                tabindex="-1"
                :disabled="!client"
                @input="
                  ($event) => {
                    const val = $event.currentTarget.value;
                    open();
                    setQuery(val);
                    refine(val);
                  }
                "
                @focus.prevent="
                  ($event) => {
                    if (searchQuery.length > 0) {
                      open();
                    }
                    refine($event.currentTarget.value);
                  }
                "
              />
              <button
                class="search-button flex items-center justify-center w-12 h-full text-br-green absolute pl-2 top-0 left-0"
                type="submit"
              >
                <SearchIcon class="w-6 h-6" />
              </button>
              <button
                v-if="currentRefinement.length > 0"
                class="absolute top-0 right-0 h-full mr-2 w-10 flex items-center justify-center text-gray-light"
                type="button"
                @click="onBackspaceClick(() => refine(''), $event)"
              >
                <BackspaceIcon class="h-6" />
              </button>
              <button
                v-else
                class="absolute top-0 right-0 h-full mr-2 w-10 flex items-center justify-center text-gray-light"
                @click="hide()"
              >
                <CloseIcon class="h-6" />
              </button>
            </form>
            <div v-if="searchQuery.length > 0" class="text-center fluid-xs leading-tightest mt-6 hidden lg:block">
              {{ $t('search.press') }} <strong>{{ $t('search.enter') }}</strong> {{ $t('search.to') }}
              <nuxt-link
                :to="{ path: $localeRootPath('search'), query: { query: searchQuery } }"
                class="underline"
                @click.native="close"
                >{{ $t('search.viewAllResults') }}</nuxt-link
              >
            </div>
            <button
              v-if="showCloseButton"
              class="ml-2 md:absolute right-0 top-0 w-8 h-12 flex items-center justify-center"
              @click="close"
            >
              <IconClose class="w-4 h-4" />
            </button>
          </div>
        </AisSearchBox>
      </div>
      <AisStateResults v-if="searchQuery.length > 1 && isOpen">
        <div
          slot-scope="{ state: { query }, results: { hits } }"
          class="absolute inset-x-0 top-full"
          style="height: calc(100vh - 328px)"
        >
          <SearchModalResults
            v-if="hits"
            ref="searchModalResults"
            class="grow overflow-y-auto shadow-xl bg-white h-full"
            :query="query"
            :hits="hits"
            @clicked="close"
          />
        </div>
      </AisStateResults>
    </div>
  </AisInstantSearch>
</template>

<script>
import { AisInstantSearch, AisSearchBox, AisStateResults, AisConfigure } from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import SearchModalResults from '@/components/layout/header/SearchModalResults';
import SearchIcon from '@/components/icons/SearchIcon';
import IconClose from '@/components/layout/header/IconClose';
import BackspaceIcon from '@/components/icons/BackspaceIcon';
import CloseIcon from '@/components/icons/CloseIcon';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BackspaceIcon,
    CloseIcon,
    IconClose,
    SearchIcon,
    SearchModalResults,
    AisInstantSearch,
    AisSearchBox,
    AisConfigure,
    AisStateResults,
  },

  props: {
    showBackdrop: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    isInModal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      client: null,
      isSearchInputFocused: false,
      sectionGroups: ['Products', 'Exercises', 'Knowledge', 'Story', 'Service'],
    };
  },
  computed: {
    ...mapGetters('search', ['searchQuery', 'indexName', 'isOpen', 'isHidden']),
    searchParameters() {
      return {
        query: this.searchQuery,
        filters: this.sectionGroups.map((g) => `sectionGroup:${g}`).join(' OR '),
      };
    },
  },
  mounted() {
    if (!this.client) this.setupSearch();
  },
  methods: {
    ...mapActions('search', ['close', 'setQuery', 'setIndexName', 'open', 'hide']),

    async setupSearch() {
      this.setIndexName();
      const algoliaClient = await algoliasearch(process.env.algoliaApplicationId, process.env.algoliaSearchApiKey);

      this.client = {
        search(requests) {
          if (requests[0].params.query === '') {
            return Promise.resolve({ results: [{}] });
          }

          return algoliaClient.search(requests);
        },
      };
    },

    focusSearchInput() {
      this.$nextTick(() => {
        if (this.$refs.searchInput) this.$refs.searchInput.focus();
      });
    },

    handleSubmit() {
      this.close();
      if (this.searchQuery.length > 0) {
        this.setQuery(this.searchQuery);
        this.$router.push({ path: this.$localeRootPath('search'), query: { query: this.searchQuery } });
      }
    },

    onBackspaceClick(callback, e) {
      e.preventDefault();
      callback();
      this.setQuery('');
      this.$refs.searchInput.focus();
    },
  },
};
</script>

<style scoped>
.search-input::-webkit-search-decoration,
.search-input::-webkit-search-cancel-button,
.search-input::-webkit-search-results-button,
.search-input::-webkit-search-results-decoration {
  @apply hidden;
}
.search-input {
  @apply appearance-none;
}
.search-input:focus {
  @apply outline-none;
}

.search-input:focus + .search-button {
  @apply text-br-green;
}
</style>
