import gql from 'graphql-tag'
import { CraftAssetFragment } from '~/components/common/CraftAsset'
import { LinkFragment } from '~/components/common/LinkField'

export const HeaderBlockBoxHeadingFragment = gql`
  fragment HeaderBlockBoxHeadingFragment on headerBlock_boxHeading_BlockType {
    typeHandle
    heading
    course
    image {
      ...CraftAssetFragment
    }
    boxPositionRight
    cta {
      ...LinkFragment
    }
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`

export const MarketingBlocksCourseConversionBannerFragment = gql`
  fragment MarketingBlocksCourseConversionBannerFragment on marketingBlocks_courseConversionBanner_BlockType {
    typeHandle
    heading
    course
    bodyText
    image {
      ...CraftAssetFragment
    }
    boxPositionRight
    cta {
      ...LinkFragment
    }
    agreementCategory {
      ... on preAssessment_preAssessment_Entry {
        title
        agreementBody
        agreementLabel
        disagreementLabel
        confirmButtonLabel
        cancelButtonLabel
      }
    }
    staticPrice: price
    staticCompareAtPrice: compareAtPrice
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`

export const ProductBlocksCourseConversionBannerFragment = gql`
  fragment ProductBlocksCourseConversionBannerFragment on productBlocks_courseConversionBanner_BlockType {
    typeHandle
    heading
    course
    bodyText
    image {
      ...CraftAssetFragment
    }
    boxPositionRight
    cta {
      ...LinkFragment
    }
    agreementCategory {
      ... on preAssessment_preAssessment_Entry {
        title
        agreementBody
        agreementLabel
        disagreementLabel
        confirmButtonLabel
        cancelButtonLabel
      }
    }
    staticPrice: price
    staticCompareAtPrice: compareAtPrice
  }
  ${CraftAssetFragment}
  ${LinkFragment}
`
