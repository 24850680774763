import Vue, { watch } from 'vue';
import { useElementVisibility } from '@vueuse/core';

export default function (context, inject) {
  const flushTempDataLayer = () => {
    if (!window) {
      return;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    if (!window.tempDataLayer) {
      return;
    }

    // sort temp data layer so that select events are pushed before view events
    window.tempDataLayer.sort((a, b) => {
      if (a.event_name.includes('select') && b.event_name.includes('view')) {
        return -1;
      }

      if (a.event_name.includes('view') && b.event_name.includes('select')) {
        return 1;
      }

      return 0;
    });

    // push all events from tempDataLayer to dataLayer
    window.dataLayer.push(...window.tempDataLayer);
    window.tempDataLayer = [];

    if (context.isDev) {
      console.log('[TRK FLUSH]', window.dataLayer);
    }
  };

  const eventHandler = (data) => {
    return function trackEvent(event = null) {
      // We push this tracked data to a temporary dataLayer array
      // If a route change is detected we flush it to the real dataLayer
      window.tempDataLayer = window.tempDataLayer || [];

      // first check if we have an existing datalayer object for the event
      let found = window.tempDataLayer.findLast((item) => {
        return item.event === data.event;
      });

      // if the items property is 14, we flush the temporary dataLayer
      const flushAfterTracking = found && found.ecommerce?.items && found.ecommerce?.items?.length === 14;

      // if we don't have an existing datalayer object for the event
      // then we create a new datalayer object
      if (!found) {
        window.tempDataLayer.push({
          event: data.event,
          event_name: data.event_name,
          ecommerce: { items: [] },
        });

        // assign found the last item in the dataLayer
        found = window.tempDataLayer[window.tempDataLayer.length - 1];
      }

      found.ecommerce.items.push(data.payload);

      if (context.isDev) {
        console.log('[TRK EVENT]', data, window.tempDataLayer);
      }

      if (flushAfterTracking) {
        flushTempDataLayer();
      }
    };
  };

  Vue.directive('track', {
    inserted(el, binding) {
      if (binding.value === false) {
        return;
      }

      let eventType = 'promotion';

      if (binding.arg === 'click') {
        // If the event is a click and has item, the event type is item
        if (binding.modifiers.item) {
          eventType = 'item';
        }

        // if the track event is added to a form element, we listen to submit event
        const eventListener = el.nodeName.toLowerCase() === 'form' ? 'submit' : 'click';

        const handler = eventHandler({
          payload: binding.value,
          event: `EEC_select_${eventType}`,
          event_name: `select_${eventType}`,
        });

        el.addEventListener(eventListener, (event) => handler(event));
      }

      if (binding.arg === 'view') {
        // If the event is a view and has item, the event type is item_list
        if (binding.modifiers.item) {
          eventType = 'item_list';
        }

        const isInViewport = useElementVisibility(el);

        // Watch when element is first time in viewport
        watch(isInViewport, (value) => {
          if (value && !el.dataset.isViewed) {
            el.dataset.isViewed = true;

            eventHandler({
              payload: binding.value,
              event: `EEC_view_${eventType}`,
              event_name: `view_${eventType}`,
            })();
          }
        });
      }
    },

    unbind(el, binding) {
      if (binding.arg === 'click') {
        el.removeEventListener('click', eventHandler);
      }
    },
  });

  if (typeof window !== 'undefined') {
    // flush dataLayer if tab is hidden
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        flushTempDataLayer();
      }
    });

    // flush dataLayer if tab is closed
    window.addEventListener('beforeunload', (event) => {
      flushTempDataLayer();
    });

    // One Trust Event Listener
    window.addEventListener('OneTrustGroupsUpdated', function (event) {
      const consentSettings = context.$getCookieConsent();

      const groups = consentSettings.groups;
      const consentId = consentSettings.consentId;

      const timestamp = new Date().toISOString(); // Correct timestamp to current date

      const valueObject = {
        con: { CMP: { a: '', m: '', p: '', s: '' } },
        v: '',
        region: '',
        reg: '',
        cus: {
          'onetrust.groups': groups,
          'onetrust.datestamp': timestamp,
          'onetrust.dsId': consentId,
        },
      };

      const cookieValue = encodeURIComponent(JSON.stringify(valueObject));
      const cookieName = '_tracking_consent';
      const domain = '.blackroll.com';
      const expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString(); // 1 year

      // Set the cookie with the SameSite attribute
      document.cookie = `${cookieName}=${cookieValue}; expires=${expires}; path=/; domain=${domain}; secure; SameSite=None`;
    });
  }

  inject('flushTempDataLayer', flushTempDataLayer);
}
